/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */

import * as React from "react";
import PropTypes from "prop-types";
import { NumberInput } from "react-admin";

import Formatters from "../../../../lib/Formatters";
import createValidationFunctionArray from "../../../../lib/Validations";

const FORMATTING_PROPS = {
  percent: { max: "100", step: "1" },
  currency: { step: "0.01" },
};

const FormattedNumberInputComponent = function FormattedNumberInputComponent({
  formatter,
  label,
  source,
  attribute,
}) {
  const { format, parse } = Formatters[formatter];
  const formattingProps = FORMATTING_PROPS[formatter];
  const { helperText, validations } = attribute;

  return (
    <NumberInput
      key={label}
      label={label}
      format={format}
      parse={parse}
      validate={createValidationFunctionArray(validations)}
      source={source}
      helperText={helperText || null}
      min={0}
      {...formattingProps}
    />
  );
};

FormattedNumberInputComponent.propTypes = {
  formatter: PropTypes.oneOf(["hours", "currency", "percent"]).isRequired,
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  attribute: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.bool),
    ])
  ).isRequired,
};

FormattedNumberInputComponent.defaultProps = {};

export default FormattedNumberInputComponent;
