import { React } from "react";

import { useRecordContext, Show, SelectInput } from "react-admin";
import { DocumentUploadButton } from "../../DocumentTab/DocumentUploadButton";

import * as Choices from "../../../SelectChoices";

const { PLAN_DOCUMENT_DISPLAY_NAMES: displayNames } = Choices;

const PlanDocumentUploadComponent = function PlanDocumentUploadComponent() {
  const planId = useRecordContext().id;
  const uploadRoute = "plan_documents";

  return (
    <Show title=" > Upload">
      <DocumentUploadButton uploadRoute={uploadRoute} resourceId={planId}>
        <SelectInput
          label="Display Name"
          source="display_name"
          choices={displayNames}
        />
      </DocumentUploadButton>
    </Show>
  );
};

const PlanDocumentUploadTab = function PlanDocumentUploadTab() {
  return <PlanDocumentUploadComponent key="PlanDocumentUploadComponent" />;
};

export default PlanDocumentUploadTab;
