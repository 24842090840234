/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import {
  ReferenceInput,
  TextInput,
  NumberInput,
  DateInput,
  RichTextField,
  RadioButtonGroupInput,
  SelectInput,
  BooleanInput,
  AutocompleteInput,
  NullableBooleanInput,
} from "react-admin";
import { FormattedTextInput } from "./FormattedTextInput";
import { FormattedNumberInput } from "./FormattedNumberInput";
import { DefaultReferenceComponent } from "./DefaultReferenceComponent";
import { MultiCheckboxComponent } from "./MultiCheckboxComponent";
import { MultiCheckboxReferenceComponent } from "./MultiCheckboxReferenceComponent";
import { ContributionInputComponent } from "./ContributionInputComponent";
import createValidationFunctionArray from "../../../lib/Validations";

// eslint-disable-next-line no-unused-vars
const DefaultReference = function DefaultReference(attribute, references) {
  const { label } = attribute;

  return (
    <DefaultReferenceComponent
      key={label}
      label={label}
      attribute={attribute}
      references={references}
    />
  );
};

// eslint-disable-next-line no-unused-vars
const AutocompleteReference = function AutocompleteReference(
  attribute,
  references
) {
  const {
    label,
    source,
    validations,
    input_text_attributes: inputTextAttrs,
  } = attribute;
  const [nextReference] = references;
  const [referenceResource] = nextReference;

  const inputText = (record) => {
    const inputTextValues = inputTextAttrs.map(
      (attribute) => record[attribute]
    );
    return inputTextValues.join(" ");
  };

  return (
    <ReferenceInput label={label} source={source} reference={referenceResource}>
      <AutocompleteInput
        sx={{ minWidth: "400px", maxWidth: "auto" }}
        filterToQuery={(query) => ({ query })}
        optionText={({ content }) => (
          <RichTextField source="content" record={{ content }} />
        )}
        inputText={inputText}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
        suggestionLimit={20}
        validate={createValidationFunctionArray(validations)}
      />
    </ReferenceInput>
  );
};

const MultiCheckboxReference = function MultiCheckboxReference(
  attribute,
  references
) {
  const { label, validations } = attribute;

  return (
    <MultiCheckboxReferenceComponent
      key={label}
      attribute={attribute}
      references={references}
      validations={validations}
    />
  );
};

const MultiCheckbox = function MultiCheckbox(attribute) {
  const { label } = attribute;

  return <MultiCheckboxComponent key={label} attribute={attribute} />;
};

const Reference = function Reference(attribute) {
  const { type, reference } = attribute;
  let component = {};
  switch (type) {
    case "multi-reference":
      component = MultiCheckboxReference(attribute, reference);
      break;
    case "autocomplete":
      component = AutocompleteReference(attribute, reference);
      break;
    default:
      component = DefaultReference(attribute, reference);
      break;
  }
  return component;
};

const Number = function Number(attribute) {
  const { label, source, validations } = attribute;
  return (
    <NumberInput
      label={label}
      validate={createValidationFunctionArray(validations)}
      key={label}
      source={source}
      step={1}
    />
  );
};

const Text = function Text(attribute) {
  const {
    label,
    source,
    helperText,
    textInputType,
    validations,
    multiline,
    style,
  } = attribute;
  return (
    <TextInput
      type={textInputType || null}
      helperText={helperText || null}
      label={label}
      validate={createValidationFunctionArray(validations)}
      key={label}
      source={source}
      multiline={multiline}
      style={style}
    />
  );
};

const Boolean = function Boolean(attribute) {
  const { label, source, helperText, validations } = attribute;
  return (
    <BooleanInput
      helperText={helperText || null}
      label={label}
      validate={createValidationFunctionArray(validations)}
      key={label}
      source={source}
    />
  );
};

const NullableBoolean = function NullableBoolean(attribute) {
  const {
    label,
    source,
    helperText,
    validations,
    nullLabel,
    falseLabel,
    trueLabel,
  } = attribute;

  return (
    <NullableBooleanInput
      helperText={helperText || null}
      label={label}
      validate={createValidationFunctionArray(validations)}
      key={label}
      source={source}
      {...(nullLabel ? { nullLabel: nullLabel } : {})}
      falseLabel={falseLabel || "No"}
      trueLabel={trueLabel || "Yes"}
    />
  );
};

const Date = function Date(attribute) {
  const { label, source, validations } = attribute;
  return (
    <DateInput
      label={label}
      validate={createValidationFunctionArray(validations)}
      key={label}
      source={source}
    />
  );
};

const Radio = function Radio(attribute) {
  const { label, source, choices, validations } = attribute;
  if (!choices) {
    throw Error("Radio Button input should have an options key in its config.");
  }

  return (
    <RadioButtonGroupInput
      label={label}
      validate={createValidationFunctionArray(validations)}
      key={label}
      source={source}
      choices={choices}
    />
  );
};

const Dropdown = function Dropdown(attribute) {
  const { label, source, choices, validations, defaultValue } = attribute;

  return (
    <SelectInput
      sx={{ minWidth: "200px" }}
      label={label}
      validate={createValidationFunctionArray(validations)}
      key={label}
      source={source}
      choices={choices}
      defaultValue={defaultValue}
    />
  );
};

const Ssn = function Ssn(attribute) {
  const { label, source } = attribute;

  return (
    <FormattedTextInput
      formatter="SSN"
      key={label}
      label={label}
      source={source}
      attribute={attribute}
    />
  );
};

const Hours = function Hours(attribute) {
  const { label, source } = attribute;

  return (
    <FormattedNumberInput
      formatter="hours"
      key={label}
      label={label}
      source={source}
      attribute={attribute}
    />
  );
};

const PhoneNumber = function PhoneNumber(attribute) {
  const { label, source } = attribute;
  return (
    <FormattedTextInput
      formatter="phoneNumber"
      label={label}
      source={source}
      attribute={attribute}
    />
  );
};

const Contribution = function Contribution(attribute) {
  const { label } = attribute;

  return <ContributionInputComponent key={label} attribute={attribute} />;
};

const DefaultInput = function DefaultInput(attribute) {
  const { type } = attribute;
  let component = {};
  switch (type) {
    case "multi-checkbox":
      component = MultiCheckbox(attribute);
      break;
    case "contribution":
      component = Contribution(attribute);
      break;
    case "number":
      component = Number(attribute);
      break;
    case "ssn":
      component = Ssn(attribute);
      break;
    case "radio":
      component = Radio(attribute);
      break;
    case "drop-down":
      component = Dropdown(attribute);
      break;
    case "date":
      component = Date(attribute);
      break;
    case "phoneNumber":
      component = PhoneNumber(attribute);
      break;
    case "hours":
      component = Hours(attribute);
      break;
    case "boolean":
      component = Boolean(attribute);
      break;
    case "nullable-boolean":
      component = NullableBoolean(attribute);
      break;
    default:
      component = Text(attribute);
      break;
  }
  return component;
};

const ModifiableAttribute = function ModifiableAttribute(attribute) {
  let component = {};
  const { reference } = attribute;
  if (reference) {
    component = Reference(attribute);
  } else {
    component = DefaultInput(attribute);
  }
  return component;
};

export default ModifiableAttribute;
