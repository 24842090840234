import React from "react";

import {
  RecordContextProvider,
  SimpleShowLayout,
  useResourceContext,
  ShowButton,
} from "react-admin";

import { Paper, Grid, Typography } from "@mui/material";

import NoyoObjectMapper from "./NoyoObjectMapper";

const sageResourceNames = {
  members: "Member",
  dependents: "Dependent",
  company_plan_offerings: "Plan Offering",
};

const MatchResolver = function MatchResolver({
  sageObject,
  noyoChoices,
  children,
}) {
  const resource = useResourceContext();

  return (
    <RecordContextProvider value={sageObject}>
      <Paper sx={{ padding: "1em" }}>
        <Grid container spacing={0}>
          <Grid item container direction="row">
            <Grid item>
              <Typography variant="body2">
                Sage {sageResourceNames[resource]} to Map
              </Typography>
              <SimpleShowLayout>
                {children}
                <ShowButton target="_blank" />
              </SimpleShowLayout>
            </Grid>
            <Grid item>
              <NoyoObjectMapper
                noyoChoices={noyoChoices}
                sageObject={sageObject}
                resource={resource}
              >
                {children}
              </NoyoObjectMapper>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </RecordContextProvider>
  );
};

export default MatchResolver;
