/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Edit, SimpleForm } from "react-admin";
import { Attribute } from "../GenericAttributes";
import ShowTitle from "./ShowTitle";
import { ParentRecordContextProvider } from "../contexts";

const GenericEdit = function GenericEdit(config) {
  return function EditComponent(props) {
    return (
      <Edit {...props} redirect="show" title={<ShowTitle config={config} />}>
        <ParentRecordContextProvider resourceIdKey={config.resource_id_key}>
          <SimpleForm>
            {config.attributes.map((attribute) => Attribute("Edit", attribute))}
          </SimpleForm>
        </ParentRecordContextProvider>
      </Edit>
    );
  };
};

export default GenericEdit;
