import * as Choices from "../../SelectChoices";

const id = { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] };

const member_id = {
  source: "id",
  label: "Member",
  reference: [["members", "member_id"]],
  exclude: ["Create", "Edit"],
};

const company_retirement_plan_id = {
  source: "id",
  label: "Company Retirement Plans",
  reference: [["company_retirement_plans", "company_retirement_plan_id"]],
  sort: "id",
  exclude: ["Create", "Edit"],
};
const company_retirement_plan_start_date = {
  source: "start_date",
  label: "Company Retirement Plans",
  reference: [["company_retirement_plans", "company_retirement_plan_id"]],
  type: "drop-down",
  filter_params: [["company_id", "company_id"]],
  validations: { required: true },
  exclude: ["List"],
};

const status = {
  source: "status",
  label: "Status",
  choices: Choices.COMPANY_RETIREMENT_OFFER_STATUS_CHOICES,
  type: "drop-down",
  validations: { required: true },
};

const start_date = {
  source: "start_date",
  label: "Start Date",
  type: "date",
  validations: { required: true },
};

const end_date = {
  source: "end_date",
  label: "End Date",
  type: "date",
};

export default {
  top_level_resource_config: {
    title: "Member Retirement Offerings",
    backend_resource: "member_retirement_offerings",
    attributes: [
      id,
      company_retirement_plan_start_date,
      status,
      member_id,
      start_date,
      end_date,
    ],
    components: ["Show", "Create", "Edit"],
  },
  sub_resource_config: {
    tab_name: "Retirement Offerings",
    backend_resource: "member_retirement_offerings",
    linking_field: "member_id",
    attributes: [
      id,
      company_retirement_plan_id,
      company_retirement_plan_start_date,
      status,
      start_date,
      end_date,
    ],
    components: ["Show", "Edit", "Create", "List"],
  },
};
