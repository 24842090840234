import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material";

function EntityTable({ table, sx }) {
  return (
    <>
      <h2>Existing Records</h2>
      <TableContainer component={Paper} sx={sx}>
        <Table sx={sx}>
          <TableHead>
            <TableRow>
              {table[0].map((header, index) => (
                <TableCell
                  key={index}
                  {...(index === 0 ? {} : { align: "right" })}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {table.slice(1).map((row) => (
              <TableRow key={row[0]}>
                {row.map((field, index) => (
                  <TableCell
                    key={index}
                    {...(index === 0 ? {} : { align: "right" })}
                  >
                    {field}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default EntityTable;
