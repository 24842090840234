import { React } from "react";
import { Attribute } from "../../../GenericAttributes";
import { Box } from "@mui/material";

const PlanMetadataCreateAttributes = function (props) {
  const { attributes } = props;

  return (
    <Box sx={{ display: "grid" }}>
      {attributes.map((attribute) => Attribute("Create", attribute))}
    </Box>
  );
};

export default PlanMetadataCreateAttributes;
