/* eslint-disable react/jsx-no-bind */

import * as React from "react";
import PropTypes from "prop-types";
import { TextInput } from "react-admin";

import Formatters from "../../../../lib/Formatters";
import createValidationFunctionArray from "../../../../lib/Validations";

const FormattedTextInputComponent = function FormattedTextInputComponent({
  formatter,
  label,
  source,
  attribute,
}) {
  const { format, parse } = Formatters[formatter];
  const { helperText, validations } = attribute;
  return (
    <TextInput
      format={format}
      parse={parse}
      validate={createValidationFunctionArray(validations)}
      key={label}
      source={source}
      helperText={helperText || null}
    />
  );
};

FormattedTextInputComponent.propTypes = {
  formatter: PropTypes.oneOf(["SSN", "phoneNumber"]).isRequired,
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  attribute: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.bool,
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ])
      ),
    ])
  ).isRequired,
};

FormattedTextInputComponent.defaultProps = {};

export default FormattedTextInputComponent;
