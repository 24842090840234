import React from "react";
import { useSidebarState } from "react-admin";
import Avatar from "@mui/material/Avatar";
import ListIcon from "@mui/icons-material/ViewList";

import { THEME } from "lib/themes";

const ResourceIcon = ({ title }) => {
  const [isSidebarOpen] = useSidebarState();

  if (isSidebarOpen) {
    return <ListIcon fontSize="medium" />;
  }

  return (
    <Avatar
      children={title[0]}
      sx={{
        backgroundColor: THEME.palette.secondary["300"],
        fontSize: "1rem",
        width: 24,
        height: 24,
      }}
    />
  );
};

export default ResourceIcon;
