import { React, useState, useEffect, useCallback } from "react";
import { useRecordContext } from "react-admin";
import EntityTable from "../InternationalCompanyEnrollments/EntityTable";
import EntityForm from "../InternationalCompanyEnrollments/EntityForm";
import Dropdown from "../InternationalCompanyEnrollments/Dropdown";
import { Box } from "@mui/material";
import { BASE_URL } from "lib/constants";
import { tableForSchema } from "../InternationalCompanyEnrollments/schemaHelpers";
import HttpClient from "lib/HttpClient";

const InternationalMemberOfferingsTabComponent = (props) => {
  const canonicalMemberId = useRecordContext(props).external_ids.justworks_cdms;
  const [state, setState] = useState({
    error: null,
    validBenefitTypes: ["health"],
    selectedBenefitType: "",
    memberOfferingsSchema: null,
    memberOfferings: null,
  });
  const [table, setTable] = useState([]);
  const schemaUrl = `${BASE_URL}/international/${state.selectedBenefitType}/member_offerings/schema`;
  const memberOfferingsUrl = `${BASE_URL}/international/${state.selectedBenefitType}/member_offerings?member_id=${canonicalMemberId}`;

  const updateState = useCallback(
    (key, result) => {
      setState((prevState) => ({ ...prevState, [key]: result }));
    },
    [setState]
  );

  const setSelectedBenefitType = (event) => {
    updateState("selectedBenefitType", event.target.value);
  };

  const fetchDataCallback = useCallback(
    async (url, key) => {
      const { json } = await HttpClient(url);
      updateState(key, json);
    },
    [updateState]
  );

  function handleSubmit() {
    fetchDataCallback(memberOfferingsUrl, "memberOfferings");
  }

  // Fetch schema and member offerings
  useEffect(() => {
    if (state.selectedBenefitType) {
      fetchDataCallback(schemaUrl, "memberOfferingsSchema");
      fetchDataCallback(memberOfferingsUrl, "memberOfferings");
    }
  }, [
    fetchDataCallback,
    schemaUrl,
    memberOfferingsUrl,
    state.selectedBenefitType,
  ]);

  // Fetch table
  useEffect(() => {
    const propertiesToIgnoreInList = [
      "member_id",
      "external_ids",
      "created_at",
      "updated_at",
    ];
    async function getTable() {
      if (state.memberOfferingsSchema && state.memberOfferings) {
        setTable(
          tableForSchema(
            state.memberOfferingsSchema,
            state.memberOfferings,
            propertiesToIgnoreInList
          )
        );
      }
    }

    getTable();
  }, [state.memberOfferings, state.memberOfferingsSchema]);

  const propertiesToIgnoreInCreate = [
    "id",
    "selected_plan_id",
    "selected_dependent_ids",
    "coverage_tier",
    "external_ids",
    "created_at",
    "updated_at",
  ];

  return (
    <Box sx={{ margin: [2, 0, 0, 2] }}>
      <Dropdown
        label="Benefit Type"
        selected={state.selectedBenefitType}
        setSelected={setSelectedBenefitType}
        options={state.validBenefitTypes}
        sx={{ width: 200 }}
      />
      {state.memberOfferingsSchema ? (
        <EntityForm
          presetFormValues={{ member_id: canonicalMemberId }}
          schema={state.memberOfferingsSchema || {}}
          propertiesToIgnore={propertiesToIgnoreInCreate}
          baseApiUrl={BASE_URL + "/international"}
          createUrl={memberOfferingsUrl}
          onSuccess={handleSubmit}
          sx={{ width: 300 }}
        />
      ) : (
        <></>
      )}
      {table.length > 0 ? <EntityTable table={table} /> : <></>}
    </Box>
  );
};

const InternationalMemberOfferingsTab = () => {
  return <InternationalMemberOfferingsTabComponent />;
};

export default InternationalMemberOfferingsTab;
