/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import {
  Datagrid,
  List,
  ShowButton,
  ResourceContextProvider,
  useRecordContext,
} from "react-admin";
import { Attribute } from "../../../../GenericAttributes";
import ShowTitle from "../../../ShowTitle";

const SubResourceList = function SubResourceList(config) {
  const { components, attributes, filters = [] } = config;
  const backendResource = config.backend_resource;
  const linkingField = config.linking_field;
  const sortOrder = config.sort_order;
  const sortField = config.sort_field;
  const shouldShow = components.includes("Show");
  const SubResourceListComponent = function SubResourceListComponent(props) {
    const linkingId = useRecordContext().id;
    const filter = {
      [linkingField]: linkingId,
    };
    const listProps = { ...props, resource: backendResource };
    const filterConfig = attributes.filter(
      (attribute) =>
        filters.includes(attribute.source) || filters.includes(attribute.label)
    );
    let filterComponents = filterConfig.map((attribute) =>
      Attribute("Edit", attribute)
    );

    return (
      <ResourceContextProvider value={backendResource}>
        <List
          exporter={config.exporter}
          title={<ShowTitle config={config} />}
          sort={{ field: sortField || "created_at", order: sortOrder || "ASC" }}
          perPage={25}
          {...listProps}
          empty={false}
          filter={filter}
          filters={filterComponents}
          disableSyncWithLocation={true}
        >
          <Datagrid>
            {config.attributes.map((attribute) => Attribute("List", attribute))}
            {shouldShow ? <ShowButton /> : undefined}
          </Datagrid>
        </List>
      </ResourceContextProvider>
    );
  };

  return SubResourceListComponent;
};

export default SubResourceList;
