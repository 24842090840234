import * as React from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../../CustomComponents/ProcessManager";

const MemberDependentSetupManagerComponent =
  function MemberDependentSetupManagerComponent(props) {
    const memberDependentId = useRecordContext(props).id;

    return (
      <ProcessManager
        processName="member_dependent_setup"
        ownerId={memberDependentId}
        kickoffButtonLabel="Sync dependent"
      />
    );
  };

const MemberDependentSetupManager = function MemberDependentSetupManager() {
  return (
    <MemberDependentSetupManagerComponent
      label="Dependent Noyo Sync"
      key="MemberDependentSetupManagerComponent"
    />
  );
};

export default MemberDependentSetupManager;
