import { useState } from "react";
import { Button, Confirm, useRecordContext, useNotify } from "react-admin";
import { useTakeAction } from "../../../../lib/Hooks";

const ResetPlanSelectionsComponent = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const id = record.id;
  const method = "POST";
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    takeAction();
    setOpen(false);
  };
  const [takeAction] = useTakeAction(
    "company_benefit_selection_windows",
    { id, subAction: "reset" },
    {
      method,
      onSuccess: () => {
        notify(`Plan Selection ${id} has been successfully reset.`, {
          type: "success",
        });
      },
      onError: (response) => {
        notify(response.message, { type: "error" });
      },
    }
  );

  return (
    <>
      <Button label="Reset Plan Selection" onClick={handleClick} />
      <Confirm
        isOpen={open}
        title="Reset Plan Selection"
        content={`Are you sure you want to reset plan selection ${record.id}?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

function ResetPlanSelections() {
  return <ResetPlanSelectionsComponent />;
}

export default ResetPlanSelections;
