import { React, useContext } from "react";
import PropTypes from "prop-types";
import { CheckboxGroupInput, DateInput, required } from "react-admin";

import { QleSubmissionFormContext } from "../../context";

const DependentDisenrollComponent = function DependentDisenrollComponent(
  props
) {
  const { benefitType, enrolledDependentIds } = props;
  const { currentOfferingData } = useContext(QleSubmissionFormContext);

  const { allDependents } = currentOfferingData;

  const choices = enrolledDependentIds.map((dependentId) => {
    const dependent = allDependents[dependentId];

    return {
      id: dependentId,
      name: `${dependent.first_name} ${dependent.last_name}`,
    };
  });

  return (
    <div>
      <div key={benefitType}>
        <h4 style={{ textTransform: "capitalize" }}>{benefitType}</h4>
        <DateInput
          label="Coverage End Date"
          source={`coverage_modifications.${benefitType}.action_details.coverage_end_date`}
          validate={required()}
        />
        <br />
        <CheckboxGroupInput
          label={`Dependents to remove from ${benefitType} policy:`}
          source={`coverage_modifications.${benefitType}.action_details.removed_dependent_ids`}
          choices={choices}
          validate={required()}
        />
      </div>
    </div>
  );
};
DependentDisenrollComponent.propTypes = {
  benefitType: PropTypes.string.isRequired,
  enrolledDependentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DependentDisenrollComponent;
