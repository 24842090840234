const id = {
  source: "id",
  label: "Company HSA Enrollment ID",
  sortable: true,
  reference: [["company_hsa_enrollments", "company_hsa_enrollment_id"]],
};
const employeeContributionAmount = {
  source: "employee_contribution_amount",
  validations: { required: true },
  label: "Employee",
  sortable: true,
  type: "number",
};
const employeePlusSpouseContributionAmount = {
  source: "employee_plus_spouse_contribution_amount",
  validations: { required: true },
  label: "Employee + Spouse",
  sortable: true,
  type: "number",
};
const employeePlusChildrenContributionAmount = {
  source: "employee_plus_children_contribution_amount",
  validations: { required: true },
  label: "Employee + Children",
  sortable: true,
  type: "number",
};
const employeePlusFamilyContributionAmount = {
  source: "employee_plus_family_contribution_amount",
  validations: { required: true },
  label: "Family",
  sortable: true,
  type: "number",
};

export default {
  top_level_resource_config: {
    title: "HSA Contribution Schemes",
    backend_resource: "company_hsa_enrollment_contribution_schemes",
    attributes: [
      id,
      employeeContributionAmount,
      employeePlusSpouseContributionAmount,
      employeePlusChildrenContributionAmount,
      employeePlusFamilyContributionAmount,
    ],
    components: ["Show", "Edit"],
  },
  sub_resource_config: {
    tab_name: "HSA Contribution Schemes",
    backend_resource: "company_hsa_enrollment_contribution_schemes",
    linking_field: "company_hsa_enrollment_id",
    attributes: [
      id,
      employeeContributionAmount,
      employeePlusSpouseContributionAmount,
      employeePlusChildrenContributionAmount,
      employeePlusFamilyContributionAmount,
    ],
    components: ["Show", "List", "Edit"],
  },
};
