import UtilityView from "./UtilityView";

export default {
  sub_resource_config: {},
  top_level_resource_config: {
    title: "Utilities",
    backend_resource: "utility",
    components: [],
    custom_views: {
      list: UtilityView,
    },
  },
};
