import { React } from "react";
import PropTypes from "prop-types";

const ProcessStatus = function ProcessStatus({
  status, // The status object
}) {
  if (!status.started) return null;

  let statusText;

  if (status.complete) {
    statusText = "Complete";
  } else if (!status.healthy) {
    statusText = "Error";
  } else if (status.terminal) {
    statusText = "Halted";
  } else {
    statusText = "Processing";
  }

  return (
    <div>
      <p style={{ margin: 0 }}>Status: {statusText}</p>
      {status.started && (
        <p style={{ margin: 0 }}>Current State: {status.current_state}</p>
      )}
    </div>
  );
};

ProcessStatus.propTypes = {
  status: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
};

export default ProcessStatus;
