/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Resource } from "react-admin";
import BlueprintConfig from "./BlueprintConfig";

import {
  GenericList,
  GenericShow,
  GenericCreate,
  GenericEdit,
} from "./GenericComponents";
import { ResourceIcon } from "./CustomComponents";

const ResourceComponent = function ResourceComponent(name, config) {
  const {
    title,
    components,
    custom_views: customViews = {},
    record_representation: recordRepresentation = null,
  } = config;

  const customViewComponents = {};
  Object.keys(customViews).forEach((view) => {
    customViewComponents[view] = customViews[view](config);
  });

  const componentsToInclude = {
    show: components.includes("Show") ? GenericShow(config) : undefined,
    list: components.includes("List") ? GenericList(config) : undefined,
    create: components.includes("Create") ? GenericCreate(config) : undefined,
    edit: components.includes("Edit") ? GenericEdit(config) : undefined,
    ...customViews,
  };

  return (
    <Resource
      {...componentsToInclude}
      name={name}
      options={{ label: title }}
      key={name}
      icon={() => <ResourceIcon title={title} />}
      recordRepresentation={recordRepresentation}
    />
  );
};

const Blueprint = function Blueprint(config = BlueprintConfig) {
  return Object.keys(config).map((key) => {
    const resourceConfig = config[key];
    const name = resourceConfig.backend_resource;

    return ResourceComponent(name, resourceConfig);
  });
};

export default Blueprint;
