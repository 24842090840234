import { datadogRum } from "@datadog/browser-rum";
import { API_HOST } from "../constants";

const AUTH_HEADER = "Authorization";
const AUTH_STORAGE_LOCATION = "auth";

const SIGNIN_URL = `${API_HOST}/api/v0/users/sign_in.json`;
const SIGNOUT_URL = `${API_HOST}/api/v0/users/sign_out.json`;
const CHECKAUTH_URL = `${API_HOST}/api/v0/check_auth`;

const login = async ({ username, password }) => {
  const credentialsObject = {
    user: {
      email: username,
      password,
    },
  };
  const request = new Request(SIGNIN_URL, {
    method: "POST",
    body: JSON.stringify(credentialsObject),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  const response = await fetch(request);
  if (response.ok) {
    const token = response.headers.get(AUTH_HEADER);
    const responseBody = await response.json();
    const identity = {
      id: responseBody.id,
      token,
    };
    localStorage.setItem(AUTH_STORAGE_LOCATION, JSON.stringify(identity));

    datadogRum.setUser({
      id: identity.id,
    });
  } else {
    throw new Error("Login failed");
  }
};

const checkAuth = async () => {
  const identity = localStorage.getItem(AUTH_STORAGE_LOCATION);
  if (identity === null) {
    throw new Error("Your session has expired");
  }

  async function makeAuthRequest(retryCount = 0) {
    if (retryCount > 3) {
      throw new Error("Your session has expired");
    }

    await new Promise((resolve) => setTimeout(resolve, 250 * retryCount));

    const { token } = JSON.parse(identity);
    const request = new Request(CHECKAUTH_URL, {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        return Promise.resolve("Authenticated");
      }

      if (response.status >= 500) {
        return makeAuthRequest(retryCount + 1);
      }

      throw new Error("Your session has expired");
    } catch (error) {
      if (error.message.toLowerCase().includes("failed to fetch")) {
        return makeAuthRequest(retryCount + 1);
      }

      throw new Error("Your session has expired");
    }
  }

  return makeAuthRequest();
};

const logout = (params) => {
  const identity = localStorage.getItem(AUTH_STORAGE_LOCATION);
  if (identity === null) {
    return Promise.resolve();
  }
  const { token } = JSON.parse(identity);
  const request = new Request(SIGNOUT_URL, {
    method: "DELETE",
    headers: new Headers({
      Authorization: token,
      "Content-Type": "application/json",
    }),
  });
  fetch(request);
  localStorage.removeItem(AUTH_STORAGE_LOCATION);
  return Promise.resolve();
};

const getIdentity = async () => {
  try {
    const identity = localStorage.getItem(AUTH_STORAGE_LOCATION);
    if (identity === null) {
      throw new Error("Cannot resolve user identity");
    }
    const { id } = JSON.parse(identity);
    return Promise.resolve({ id });
  } catch (error) {
    return Promise.reject(error);
  }
};

const checkError = (error) => {
  const { status } = error;
  if (status === 401 || status === 403) {
    localStorage.removeItem(AUTH_STORAGE_LOCATION);
    return Promise.reject();
  }
  return Promise.resolve();
};

export {
  login,
  checkAuth,
  logout,
  getIdentity,
  checkError,
  SIGNIN_URL,
  CHECKAUTH_URL,
  SIGNOUT_URL,
};
