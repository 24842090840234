import { React } from "react";
import { Edit, SimpleForm } from "react-admin";
import { Inputs } from "./components/Inputs";

const DocumentRequirementEdit = function DocumentRequirementEdit() {
  return (
    <Edit redirect="show">
      <SimpleForm>{Inputs.map((field) => field)}</SimpleForm>
    </Edit>
  );
};

export default DocumentRequirementEdit;
