import * as Choices from "../../SelectChoices";

const id = { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] };

const member_id = {
  source: "id",
  label: "Member",
  reference: [["members", "member_id"]],
  exclude: ["Create", "Edit"],
};

const member_retirement_offering_id = {
  source: "id",
  label: "Member Retirement Offering",
  reference: [["member_retirement_offerings", "member_retirement_offering_id"]],
  type: "drop-down",
  filter_params: [["member_id", "member_id"]],
  validations: { required: true },
};

const status = {
  source: "status",
  label: "Status",
  choices: Choices.MEMBER_RETIREMENT_ENROLLMENT_STATUS_CHOICES,
  type: "drop-down",
  validations: { required: true },
};

const start_date = {
  source: "start_date",
  label: "Start Date",
  type: "date",
  validations: { required: true },
};

const end_date = {
  source: "end_date",
  label: "End Date",
  type: "date",
  exclude: ["Create", "Edit"],
};

export default {
  top_level_resource_config: {
    title: "Member Retirement Enrollments",
    backend_resource: "member_retirement_enrollments",
    attributes: [
      id,
      status,
      start_date,
      end_date,
      member_id,
      member_retirement_offering_id,
    ],
    components: ["Show", "Create", "Edit"],
  },
  sub_resource_config: {
    tab_name: "Retirement Enrollments",
    backend_resource: "member_retirement_enrollments",
    linking_field: "member_id",
    attributes: [
      id,
      member_id,
      member_retirement_offering_id,
      status,
      start_date,
      end_date,
    ],
    components: ["Show", "Edit", "Create", "List"],
  },
};
