import { React } from "react";
import { ShowButton, useRecordContext } from "react-admin";
import { useGetHistory } from "../../../../../../../lib/Hooks";

const HistoryShowButton = function HistoryShowButton(config) {
  const { backend_resource: resource, linking_field: reference } = config;

  const HistoryShowButtonComponent = function HistoryShowButtonComponent({
    parentId,
  }) {
    const selectedTimeLineIdentifiers = useRecordContext();
    const recordBase = {
      [reference]: parentId,
      ...selectedTimeLineIdentifiers,
    };

    const { data } = useGetHistory(resource, { filter: recordBase });
    const history = data || [];
    const currentRecord = history[history.length - 1];

    if (!currentRecord) return null;

    return (
      <ShowButton
        resource={resource}
        record={currentRecord}
        sx={{ marginLeft: "10px", marginTop: "15px" }}
      />
    );
  };

  return HistoryShowButtonComponent;
};

export default HistoryShowButton;
