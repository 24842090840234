import * as React from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../ProcessManager";

const NewHireMedicalInsuranceComponent =
  function NewHireMedicalInsuranceComponent(props) {
    const memberBenefitSelectionId = useRecordContext(props).id;
    const memberBenefitSelectionStatus =
      useRecordContext(props).selection_status;
    const readyToEnroll = useRecordContext(props).ready_to_enroll;

    if (
      (memberBenefitSelectionStatus === "confirmed" && readyToEnroll) ||
      memberBenefitSelectionStatus === "enrolled"
    ) {
      return (
        <ProcessManager
          processName="new_hire_medical_insurance_enrollment"
          ownerId={memberBenefitSelectionId}
          kickoffButtonLabel="Sync to Noyo"
        />
      );
    } else if (memberBenefitSelectionStatus === "confirmed") {
      return (
        <span style={{ fontSize: "0.875rem", color: "red" }}>
          Please complete enrollment prerequisites before syncing with Noyo.
        </span>
      );
    }

    return null;
  };

const NewHireMedicalInsuranceManager =
  function NewHireMedicalInsuranceManager() {
    return (
      <NewHireMedicalInsuranceComponent
        label="Selection Noyo Sync"
        key="NewHireMedicalInsuranceComponent"
      />
    );
  };

export default NewHireMedicalInsuranceManager;
