import { React } from "react";
import PropTypes from "prop-types";

import { ShowButton } from "react-admin";

import { ProcessStatus } from "./components";

import { ActionButton } from "../ActionButton";
import { useGetProcessStatus } from "../../../../lib/Hooks";

const ProcessManager = function ProcessManager({
  processName, // The name of the process to be managed.
  ownerId, // UUID of the resource that owns the process
  kickoffButtonLabel, // Label to use for the kickoff Button
}) {
  const { data, isLoading, loadingError } = useGetProcessStatus(
    processName,
    ownerId
  );

  if (loadingError) {
    return (
      <div>
        <p>Error</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  if (data.started) {
    return (
      <div>
        <ProcessStatus status={data} />
        <ShowButton
          resource="workflow_runs"
          record={{ id: data.run_id }}
          label="Details"
        />
      </div>
    );
  }

  return (
    <div>
      <ProcessStatus status={data} />
      {kickoffButtonLabel ? (
        <ActionButton
          resource={processName}
          id={ownerId}
          action="kickoff"
          label={kickoffButtonLabel}
        />
      ) : (
        "Not started"
      )}
    </div>
  );
};

ProcessManager.propTypes = {
  processName: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired,
  kickoffButtonLabel: PropTypes.string.isRequired,
};

ProcessManager.defaultProps = {};

export default ProcessManager;
