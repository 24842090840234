import React from "react";

import { SimpleShowLayout, ResourceContextProvider } from "react-admin";

import { CompanyBenefitSelectionWindowProcessingManager } from "../../../CompanyBenefitSelectionWindowProcessingManager";

const WaitingForSelectionWindowProcessingPrompt =
  function WaitingForSelectionWindowProcessingPrompt({ data }) {
    const { selection_window } = data;

    return (
      <div>
        <h4>Selection window processing status:</h4>
        <ResourceContextProvider value="company_benefit_selection_windows">
          <SimpleShowLayout record={selection_window}>
            <CompanyBenefitSelectionWindowProcessingManager />
          </SimpleShowLayout>
        </ResourceContextProvider>
      </div>
    );
  };

export default WaitingForSelectionWindowProcessingPrompt;
