import InternationalCompanyEnrollmentsTab from "./InternationalCompanyEnrollmentsTab";

export default {
  sub_resource_config: {
    tab_name: "International Enrollments",
    view_component: InternationalCompanyEnrollmentsTab(),
    components: ["CustomView"],
  },
  top_level_resource_config: {},
};
