/*
Description:
A function that returns all QLE relevant enrollment information for any given member. This
includes the following bits of data:
- current company offerings
- data for all stored dependents

Example Return Value:
{
  allOfferings: [
    medical: [
      offering1,

    ],
    dental: [
      offering2
    ]
  ]
  allDependents: {
    'dependent 1 ID': { dependent1 },
    'dependent 1 ID': { dependent2 },
  },
}
*/
const formatCompanyOfferings = function formatCompanyOfferings(offeringData) {
  if (!offeringData) return {};

  const result = offeringData.reduce((group, offering) => {
    /* eslint-disable no-param-reassign */

    const benefitType = offering.benefit_type;
    group[benefitType] = group[benefitType] ?? [];
    group[benefitType].push(offering);
    return group;
  }, {});
  /* eslint-enable no-param-reassign */

  return result;
};

const formatAllDependents = function formatAllDependents(dependentData) {
  const allDependents = {};
  if (!dependentData) return allDependents;

  dependentData.forEach((dependent) => {
    allDependents[dependent.id] = dependent;
  });

  return allDependents;
};

/* eslint-disable no-restricted-syntax */
const formatOfferingData = function formatOfferingData(
  offeringData,
  dependentData
) {
  const allOfferings = formatCompanyOfferings(offeringData);
  const allDependents = formatAllDependents(dependentData);

  return {
    allOfferings,
    allDependents,
  };
};

export default formatOfferingData;
