import { React } from "react";
import PropTypes from "prop-types";
import { DateInput, required } from "react-admin";

const EmployeeEnrollComponent = function EmployeeEnrollComponent(props) {
  const { benefitType } = props;
  return (
    <div>
      <h4 style={{ textTransform: "capitalize" }}>{benefitType}</h4>
      <DateInput
        label="Coverage Start Date"
        source={`coverage_modifications.${benefitType}.action_details.coverage_start_date`}
        validate={required()}
      />
    </div>
  );
};
EmployeeEnrollComponent.propTypes = {
  benefitType: PropTypes.string.isRequired,
};

export default EmployeeEnrollComponent;
