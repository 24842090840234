const id = {
  source: "id",
  label: "Member FSA Enrollment ID",
  sortable: true,
  reference: [["member_fsa_enrollments", "member_fsa_enrollment_id"]],
  filter_params: [["member_id", "member_id"]],
};
const amount = {
  source: "amount",
  validations: { required: true },
  label: "Amount",
  sortable: true,
  type: "number",
};
const start_date = {
  source: "start_date",
  label: "Start Date",
  sortable: true,
  type: "date",
};

const end_date = {
  source: "end_date",
  label: "End Date",
  sortable: true,
  type: "date",
};

export default {
  top_level_resource_config: {
    title: "Member FSA Contributions",
    backend_resource: "member_fsa_contributions",
    attributes: [id, amount, start_date, end_date],
    components: ["Show", "Create", "Edit"],
  },
  sub_resource_config: {
    tab_name: "Member FSA Contributions",
    backend_resource: "member_fsa_contributions",
    linking_field: "member_fsa_enrollment_id",
    attributes: [id, amount, start_date, end_date],
    components: ["Show", "Create", "List", "Edit"],
  },
};
