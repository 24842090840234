import React from "react";

import {
  Datagrid,
  TextField,
  ShowButton,
  WithRecord,
  useRecordContext,
  ArrayField,
} from "react-admin";

import { WorkflowActionButton } from "../WorkflowActionButton";

import FullNameLink from "./FullNameLink";

import MedkitIcon from "assets/icons/medical-kit.png";

const sort = { field: "last_name", order: "ASC" };

const CreateSelectionButton = function CreateSelectionButton({
  selectionWindowId,
}) {
  const member = useRecordContext();

  return (
    <WorkflowActionButton
      id={selectionWindowId}
      action="create_member_selections_for_selection_window"
      successMsg={() => `Selection created for ${member.member_name}`}
      resource="member_benefit_selections"
      label="CREATE SELECTION"
      postBody={{
        member_id: member.id,
        trigger_id: selectionWindowId,
        trigger_type: "company_benefit_selection_window",
        benefit_type: "medical",
      }}
    />
  );
};

const CreateSelectionsButton = function CreateSelectionsButton({
  selectionWindowId,
}) {
  return (
    <WorkflowActionButton
      id={selectionWindowId}
      action="create_all_selections_for_company_selection_window"
      successMsg={() => "Selections created!"}
      resource="member_benefit_selections"
      label="CREATE SELECTIONS"
      postBody={{
        trigger_id: selectionWindowId,
        trigger_type: "company_benefit_selection_window",
        benefit_type: "medical",
      }}
    />
  );
};

const EligibilityIcon = function EligibilityIcon() {
  const member = useRecordContext();
  const { eligible } = member;

  // if we don't have a key for eligible, don't show an icon
  if (eligible === undefined) {
    return "";
  }

  let style = {
    width: "25px",
  };
  if (eligible) {
    style = {
      ...style,
      filter: "hue-rotate(130deg)", // make the icon green
    };
  }
  return <img alt="eligibility-icon" style={style} src={MedkitIcon} />;
};

const WaitingPeriodDisclaimer = function WaitingPeriodDisclaimer() {
  const style = {
    "padding-top": "10px",
    "font-weight": "bold",
  };

  return (
    <div style={style}>
      NOTE: Insurance classes with waiting periods were detected for this
      company. Incoming members with waiting periods will require manual
      selections.
    </div>
  );
};

const AutogeneratingSelectionsExplainer =
  function AutogeneratingSelectionsExplainer({
    autogeneratingSelections,
    companyHasWaitingPeriods,
  }) {
    const style = {
      fontSize: ".8125rem",
      backgroundColor: "whitesmoke",
      borderRadius: "4px",
      marginBottom: "20px",
      padding: "10px",
    };

    if (autogeneratingSelections) {
      return (
        <div style={style}>
          <div>
            Selections are being automatically generated for eligible incoming
            members from Clockwork Sync.
          </div>
          <div>
            Click 'STOP AUTOGENERATING SELECTIONS!' below to turn this off.
          </div>
          {companyHasWaitingPeriods && <WaitingPeriodDisclaimer />}
        </div>
      );
    } else {
      return (
        <div style={style}>
          Click 'START AUTOGENERATING SELECTIONS!' below to kick off selection
          generation for eligible incoming members from Clockwork Sync.
        </div>
      );
    }
  };

const CreateSelectionsForSelectionWindowPrompt =
  function CreateSelectionsForSelectionWindowPrompt({ data }) {
    const {
      company_benefit_selection_window_id: selectionWindowId,
      members_with_selections_data: membersWithSelections,
      members_without_selections_data: membersWithoutSelections,
      autogenerating_selections: autogeneratingSelections,
      company_has_waiting_periods: companyHasWaitingPeriods,
    } = data;

    return (
      <div>
        <AutogeneratingSelectionsExplainer
          autogeneratingSelections={autogeneratingSelections}
          companyHasWaitingPeriods={companyHasWaitingPeriods}
        />
        {membersWithoutSelections[0] && (
          <div>
            <div>
              <CreateSelectionsButton selectionWindowId={selectionWindowId} />
            </div>
            <h4>Members without selections:</h4>
            <Datagrid data={membersWithoutSelections} sort={sort}>
              <FullNameLink source="id" reference="members" label="Name" />
              <EligibilityIcon />
              <CreateSelectionButton selectionWindowId={selectionWindowId} />
            </Datagrid>
          </div>
        )}
        {membersWithSelections[0] && (
          <div>
            <h4>Members with selections:</h4>
            <Datagrid data={membersWithSelections} sort={sort}>
              <FullNameLink source="id" reference="members" label="Member" />
              <EligibilityIcon />
              <ArrayField source="selections" disabled>
                <Datagrid className="selections-grid" bulkActionButtons={false}>
                  <TextField
                    source="benefit_type"
                    label="Benefit Type"
                    sortable={false}
                  />
                  <TextField
                    source="selection_status"
                    label="Status"
                    sortable={false}
                  />
                  <WithRecord
                    render={(record) => (
                      <ShowButton
                        resource="member_benefit_selections"
                        record={{ id: record.selection_id }}
                      />
                    )}
                  />
                </Datagrid>
              </ArrayField>
            </Datagrid>
          </div>
        )}
        <style>
          {`.selections-grid {
                .MuiTableRow-head {
                  display: none;
                }
                .RaDatagrid-rowCell {
                  border-bottom: none;
                  width: 100px;
                }
                tr {
                  display: flex;
                  justify-content: space-between;
                  &:not(:last-child) {
                    border-bottom: 1px solid rgba(224, 224, 224, 1);
                  }
                }
              }`}
        </style>
      </div>
    );
  };

export default CreateSelectionsForSelectionWindowPrompt;
