import BulkMemberExportView from "./BulkMemberExportView";

export default {
  sub_resource_config: {},
  top_level_resource_config: {
    title: "Bulk Member Export",
    backend_resource: "bulk_member_export",
    components: [],
    custom_views: {
      list: BulkMemberExportView,
    },
  },
};
