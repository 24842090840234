import {
  SimpleForm,
  ReferenceArrayInput,
  CheckboxGroupInput,
  DateInput,
  useGetList,
  Title,
  Button,
} from "react-admin";
import { useInput } from "../../../../lib/Hooks";
import { Box } from "@mui/material";
import { BulkMemberExporter } from "../../exporters";

const BulkMemberExportViewComponent = function BulkMemberExportViewComponent() {
  const [selectedOfferingStartDate, updateSelectedOfferingStartDate] =
    useInput();
  const { data: companyBenefitOfferings } = useGetList(
    "company_benefit_offerings",
    {
      filter: {
        offering_start_date: selectedOfferingStartDate,
        status: "confirmed",
      },
      pagination: { perPage: 1000 },
    },
    {
      enabled: !!selectedOfferingStartDate,
    }
  );

  const companyIdsWithSelectedOfferingStartDate = companyBenefitOfferings?.map(
    (cbo) => cbo.company_id
  );

  const bulkExportMembers = (selectedCompanies) => {
    BulkMemberExporter(selectedCompanies.name);
  };

  return (
    <div>
      <Title title="Bulk Member Exporter" />
      <Box>
        <h4>Bulk Member Exporter</h4>
        <SimpleForm toolbar={false} onSubmit={bulkExportMembers}>
          <DateInput
            label="Benefit Offering Start Date"
            source="selectedOfferingStartDate"
            value={selectedOfferingStartDate}
            onChange={updateSelectedOfferingStartDate}
          />
          {selectedOfferingStartDate && (
            <ReferenceArrayInput
              label="company"
              reference="companies"
              source="name"
              filter={{ id: companyIdsWithSelectedOfferingStartDate }}
              perPage={15000}
            >
              <CheckboxGroupInput source="name" optionText="name" />
            </ReferenceArrayInput>
          )}
          <Button
            type="submit"
            disabled={!companyBenefitOfferings?.length}
            label={"Export members for selected companies"}
          />
          <p>
            If no companies are selected, all members of all companies will be
            exported.
          </p>
        </SimpleForm>
      </Box>
    </div>
  );
};

BulkMemberExportViewComponent.propTypes = {};

const BulkMemberExportView = function BulkMemberExportView() {
  return <BulkMemberExportViewComponent />;
};

export default BulkMemberExportView;
