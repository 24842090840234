import React from "react";
import { Button } from "react-admin";

const BulkPlanUploadButtonComponent = ({ label, source }) => {
  const openBulkPlanDashboardToolInNewTab = () => {
    const dashboardUrl = `https://${window.location.host}/dashboard#/plans/bulk_create`;

    window.open(dashboardUrl, "_blank");
  };

  return (
    <div>
      <Button
        label="Have multiple plans to upload? Use Bulk Plan Upload Here."
        onClick={openBulkPlanDashboardToolInNewTab}
      />
    </div>
  );
};

export const BulkPlanUploadButton = function BulkPlanUploadButton(props) {
  return (
    <BulkPlanUploadButtonComponent
      key="BulkPlanUploadButtonComponent"
      {...props}
    />
  );
};
