import * as React from "react";
import { Labeled } from "react-admin";
import ModifiableAttribute from "./ModifiableAttribute";
import ShowableAttribute from "./ShowableAttribute";
// context: Which component is this attribute used for 'Show', 'Edit', 'Create', 'List', 'Show'
// attribute: The attribute configuration found in the blueprint config file
//                        in a sub a sub-resource form
const Attribute = function Attribute(context, attribute) {
  const {
    exclude,
    label,
    custom_component: customComponent,
    readonly,
  } = attribute;
  if (exclude && exclude.includes(context)) {
    return undefined;
  }
  if (customComponent) {
    if (typeof customComponent === "function") {
      return customComponent(attribute);
    }

    return customComponent;
  }
  if (readonly && !["Show", "List"].includes(context)) {
    return <Labeled key={label}>{ShowableAttribute(attribute)}</Labeled>;
  }
  return ["Show", "List"].includes(context)
    ? ShowableAttribute(attribute)
    : ModifiableAttribute(attribute);
};

export default Attribute;
