import { React } from "react";
import {
  TextInput,
  ReferenceInput,
  ReferenceArrayInput,
  SimpleFormIterator,
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
} from "react-admin";

import {
  ENTITY_TYPES,
  TIME_IN_BUSINESS,
  US_STATES,
} from "components/Blueprint/SelectChoices";

import CreateDocumentType from "./CreateDocumentType";
import CreateDocumentCategory from "./CreateDocumentCategory";

const inputStyle = { marginBottom: "5px" };
const shouldRenderSuggestions = (val) => {
  const parsedValue = val.trim().length;
  return parsedValue > 2 || parsedValue === 0;
};

const NameInput = <TextInput key="rule_name" source="rule_name" />;

const CategoryInput = (
  <ReferenceInput
    key="company_carrier_install_document_category_id"
    label="Category"
    source="company_carrier_install_document_category_id"
    reference="company_carrier_install_document_categories"
  >
    <AutocompleteInput
      label="Category"
      optionText="label"
      sx={inputStyle}
      filterToQuery={(query) => {
        return { query };
      }}
      shouldRenderSuggestions={shouldRenderSuggestions}
      create={<CreateDocumentCategory />}
    />
  </ReferenceInput>
);

const StatesInput = (
  <AutocompleteArrayInput
    key="applies_to.hq_states"
    label="States"
    source="applies_to.hq_states"
    choices={US_STATES}
    helperText="Empty to apply rule to all"
    sx={inputStyle}
  />
);

const ProvidersInput = (
  <ReferenceArrayInput
    key="applies_to.provider_ids"
    reference="providers"
    source="applies_to.provider_ids"
  >
    <AutocompleteArrayInput
      label="Providers"
      optionText="name"
      helperText="Empty to apply rule to all"
      sx={inputStyle}
    />
  </ReferenceArrayInput>
);

const EntityTypesInput = (
  <AutocompleteArrayInput
    key="applies_to.entity_types"
    label="Entity Types"
    source="applies_to.entity_types"
    choices={ENTITY_TYPES}
    helperText="Empty to apply rule to all"
    sx={inputStyle}
  />
);

const TimeInBusinessInput = (
  <AutocompleteArrayInput
    key="applies_to.time_in_business"
    label="Time In Business"
    source="applies_to.time_in_business"
    choices={TIME_IN_BUSINESS}
    helperText="Empty to apply rule to all"
    sx={inputStyle}
  />
);

const RequirementsInput = (
  <ArrayInput key="requirements" source="requirements">
    <SimpleFormIterator>
      <ReferenceArrayInput
        label="Requirement"
        reference="company_carrier_install_document_types"
      >
        <AutocompleteArrayInput
          optionText="label"
          filterToQuery={(query) => {
            return { query };
          }}
          shouldRenderSuggestions={shouldRenderSuggestions}
          create={<CreateDocumentType />}
        />
      </ReferenceArrayInput>
    </SimpleFormIterator>
  </ArrayInput>
);

const Inputs = [
  NameInput,
  CategoryInput,
  StatesInput,
  ProvidersInput,
  EntityTypesInput,
  TimeInBusinessInput,
  RequirementsInput,
];

const FilterInputs = [
  CategoryInput,
  StatesInput,
  ProvidersInput,
  EntityTypesInput,
  TimeInBusinessInput,
];

export { FilterInputs, Inputs };
