import * as React from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../ProcessManager";

const MemberSelectionWindowProcessingManagerComponent =
  function MemberSelectionWindowProcessingManagerComponent(props) {
    const selectionWindowId = useRecordContext(props).id;

    return (
      <ProcessManager
        processName="member_benefit_selection_window_processing"
        ownerId={selectionWindowId}
      />
    );
  };

const MemberSetupManager = function MemberSetupManager() {
  return (
    <MemberSelectionWindowProcessingManagerComponent
      label="Selection Generation"
      key="MemberSelectionWindowProcessingManagerComponent"
    />
  );
};

export default MemberSetupManager;
