import * as React from "react";
import { Admin } from "react-admin";
import { DataProvider } from "./lib/DataProvider";
import { AuthProvider } from "./lib/AuthProvider";
import { Blueprint } from "./components/Blueprint";
import { CustomLayout } from "components/Layout";
import { currentTheme } from "lib/themes";
import { ThemeProvider } from "@mui/material/styles";
import "./App.css";

const App = () => {
  return (
    <ThemeProvider theme={currentTheme}>
      <Admin
        theme={currentTheme}
        layout={CustomLayout}
        dataProvider={DataProvider}
        authProvider={AuthProvider}
      >
        {/* Automatically Created Resources */}
        {Blueprint()}
      </Admin>
    </ThemeProvider>
  );
};

export default App;
