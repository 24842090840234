import * as React from "react";
import { useRecordContext } from "react-admin";
import { ActionButton } from "../ActionButton";

const ConvertSelectionButtonComponent =
  function ConvertSelectionButtonComponent(props) {
    const memberBenefitSelectionId = useRecordContext(props).id;
    const memberBenefitSelectionStatus =
      useRecordContext(props).selection_status;
    const readyToEnroll = useRecordContext(props).ready_to_enroll;

    if (memberBenefitSelectionStatus === "confirmed") {
      if (readyToEnroll) {
        return (
          <div>
            <ActionButton
              resource="enroll_member"
              id={memberBenefitSelectionId}
              label="Enroll"
            />
          </div>
        );
      } else {
        return (
          <span style={{ fontSize: "0.875rem", color: "red" }}>
            Please complete enrollment prerequisites before converting to
            enrollment.
          </span>
        );
      }
    }

    return null;
  };

const ConvertSelectionButton = function ConvertSelectionButton() {
  return (
    <ConvertSelectionButtonComponent
      key="convert_selection_button"
      label="Convert to Enrollment"
    />
  );
};

export default ConvertSelectionButton;
