/*
  This component is used as a generic dropdown menu.
  It takes in several arguments:
  - label: the label for the dropdown
  - selected: the currently selected value
  - setSelected: a function to set the selected value
  - options: a list of options to display in the dropdown
  - sx: custom styling for the dropdown
*/
import React from "react";
import { MenuItem, Select, InputLabel } from "@mui/material";

const Dropdown = ({ label, selected, setSelected, options, sx }) => (
  <>
    <InputLabel>{label}</InputLabel>
    <Select value={selected} onChange={setSelected} sx={sx}>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </>
);

export default Dropdown;
