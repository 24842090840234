export const selectionWindows = {
  "2024-04-01": {
    startDate: "2024-03-11",
    endDate: "2024-03-18",
  },
  "2024-05-01": {
    startDate: "2024-04-11",
    endDate: "2024-04-18",
  },
  "2024-06-01": {
    startDate: "2024-05-10",
    endDate: "2024-05-17",
  },
  "2024-07-01": {
    startDate: "2024-06-11",
    endDate: "2024-06-18",
  },
  "2024-08-01": {
    startDate: "2024-07-11",
    endDate: "2024-07-18",
  },
  "2024-09-01": {
    startDate: "2024-08-09",
    endDate: "2024-08-16",
  },
  "2024-10-01": {
    startDate: "2024-09-09",
    endDate: "2024-09-13",
  },
  "2024-11-01": {
    startDate: "2024-10-11",
    endDate: "2024-10-18",
  },
  "2024-12-01": {
    startDate: "2024-11-11",
    endDate: "2024-11-18",
  },
  "2025-01-01": {
    startDate: "2024-12-06",
    endDate: "2024-12-13",
  },
};
