/* eslint-disable react/jsx-props-no-spreading */
import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useUpdate,
  useCreate,
  useRefresh,
  useNotify,
  RecordContextProvider,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  TextField,
  Labeled,
  RichTextField,
} from "react-admin";

import { useTakeAction } from "lib/Hooks";

const ExternalIdMappingForm = function ExternalIdMappingForm({
  currentRecord,
}) {
  const [update, { isLoading: updateLoading }] = useUpdate();
  const [create, { isLoading: createLoading }] = useCreate();
  const [currentId, setCurrentId] = useState(currentRecord.external_id);
  const [mappedData, setMappedData] = useState();
  const notify = useNotify();
  const refresh = useRefresh();
  const saveFunction = currentRecord && currentRecord.id ? update : create;
  const Form = (currentRecord) => {
    return (
      <RecordContextProvider value={currentRecord}>
        <SaveContextProvider
          value={{
            save,
            saving: updateLoading || createLoading,
            mutationMode: "pessimistic",
          }}
        >
          <SimpleForm sx={{ flexDirection: "row" }}>
            <Labeled>
              <TextField
                sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                source="external_id_owner"
                label="External Id Owner"
              />
            </Labeled>
            <TextInput
              source="external_id"
              label="External Id"
              onBlur={(evt) => setCurrentId(evt.target.value)}
            />
            <Labeled>
              <RichTextField
                source="data"
                record={{ data: mappedDataDisplay() }}
              />
            </Labeled>
          </SimpleForm>
        </SaveContextProvider>
      </RecordContextProvider>
    );
  };

  const save = (formValues) => {
    saveFunction(
      "external_id_mappings",
      { id: currentRecord.id, data: { ...currentRecord, ...formValues } },
      {
        onSuccess: () => {
          refresh();
          notify("External ID updated", { type: "success" });
        },
        onError: (error) => {
          refresh();
          notify(error.message, { type: "error" });
        },
      }
    );
  };

  const [takeAction, { isLoading: validationLoading }] = useTakeAction(
    "external_id_mappings",
    { action: "validate" },
    {
      onSuccess: (response) => setMappedData(response.data),
      onError: (response) => setMappedData(response.body.error),
      onSettled: () => refresh(),
    }
  );

  const validate = (id) => {
    if (!id) {
      setMappedData(undefined);
      return;
    }
    takeAction({
      id,
      options: {
        body: JSON.stringify({ ...currentRecord, external_id: id }),
      },
    });
  };

  useEffect(() => {
    if (!currentId) return;
    validate(currentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);

  const mappedDataDisplay = () => {
    if (validationLoading) return "Loading...";
    if (mappedData) return `<pre>${JSON.stringify(mappedData, null, 2)}</pre>`;
    return "Enter an ID";
  };

  return Form(currentRecord);
};

ExternalIdMappingForm.propTypes = {
  currentRecord: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ExternalIdMappingForm;
