import React from "react";
import { ShowButton, RecordContextProvider } from "react-admin";

import { CreatePlanOfferingsForBenefitOffering } from "../../../CreatePlanOfferingsForBenefitOffering";

const explainerStyle = {
  fontSize: ".8125rem",
  backgroundColor: "whitesmoke",
  borderRadius: "4px",
  marginBottom: "20px",
  padding: "10px",
};

const PlanOfferingCreate = function PlanOfferingCreate({
  companyBenefitOfferings,
}) {
  return (
    <div>
      <div style={explainerStyle}>
        Create at least one plan offering for each benefit offering.
      </div>
      {companyBenefitOfferings[0] &&
        companyBenefitOfferings.map((benefitOffering) => (
          <RecordContextProvider
            value={benefitOffering}
            key={benefitOffering.id}
          >
            <CreatePlanOfferingsForBenefitOffering
              benefitOffering={benefitOffering}
            />
          </RecordContextProvider>
        ))}
    </div>
  );
};

const CreatePlanOfferingsPrompt = function CreatePlanOfferingsPrompt({ data }) {
  const {
    company_id: companyId,
    company_benefit_offerings: companyBenefitOfferings,
  } = data;

  return (
    <div>
      <PlanOfferingCreate companyBenefitOfferings={companyBenefitOfferings} />
      <ShowButton
        label="View Company"
        resource="companies"
        record={{ id: companyId }}
      />
    </div>
  );
};

export default CreatePlanOfferingsPrompt;
