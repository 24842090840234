/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Layout } from "react-admin";
import SearchAppBar from "../SearchAppBar";

const CustomLayout = function CustomLayout(props) {
  return <Layout {...props} appBar={SearchAppBar} />;
};

export default CustomLayout;
