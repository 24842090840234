import * as React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  useRecordContext,
  RecordRepresentation,
} from "react-admin";
import createValidationFunctionArray from "../../../../lib/Validations";
import { useParentRecordContext } from "../../contexts";

const generateOptionText = (references, source) => {
  if (references.length === 0) {
    if (!!source) {
      return <TextField source={source} />;
    }

    return <RecordRepresentation />;
  }
  const [nextReference, ...remainingReferences] = references;
  const [referenceResource, referenceField] = nextReference;

  return (
    <ReferenceField
      link={false}
      source={referenceField}
      reference={referenceResource}
    >
      {generateOptionText(remainingReferences, source)}
    </ReferenceField>
  );
};

const DefaultReferenceComponent = function DefaultReferenceComponent(props) {
  const { attribute, references } = props;
  const { label, source, validations } = attribute;
  const filterParams = attribute.filter_params;
  const [sortField = "created_at", sortOrder = "DESC"] =
    attribute.sort_params || [];
  const record = useRecordContext() || {};
  const parentRecordContext = useParentRecordContext();

  const [nextReference, ...remainingReferences] = references;
  const [referenceResource, referenceField] = nextReference;

  let filter;
  if (filterParams) {
    /* eslint-disable no-param-reassign */
    filter = filterParams.reduce((result, paramPairs) => {
      const [filterKey, recordKey] = paramPairs;
      result[filterKey] =
        record[recordKey] || parentRecordContext[recordKey] || recordKey;
      return result;
    }, {});
    /* eslint-enable no-param-reassign */
  }

  return (
    <div>
      <ReferenceInput
        key={label}
        source={referenceField}
        filter={filter}
        reference={referenceResource}
        perPage={10000}
        sort={{ field: sortField, order: sortOrder }}
      >
        <SelectInput
          label={label}
          validate={createValidationFunctionArray(validations)}
          optionText={generateOptionText(remainingReferences, source)}
        />
      </ReferenceInput>
    </div>
  );
};

DefaultReferenceComponent.propTypes = {
  attribute: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
      PropTypes.bool,
    ])
  ).isRequired,
  references: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default DefaultReferenceComponent;
