import { DataProvider } from "../../../lib/DataProvider";
import { constructMemberExportRows } from "./constructMemberExportRows";
import { BCBS_HEADERS } from "./constants";
import * as xlsx from "xlsx";
import { saveAs } from "file-saver";

const { getList } = DataProvider;

async function fetchAll(resource, filter = {}, pagination = {}, sort = {}) {
  return getList(resource, { filter, pagination, sort });
}

const BulkMemberExporter = async (companies) => {
  const csvTitle = `Bulk Member Export ${new Date().toISOString()}`;

  try {
    const filter =
      companies.length > 0
        ? { company_id: companies, unmask_secrets: true }
        : { unmask_secrets: true };
    const { data: memberData } = await fetchAll("members", filter);

    if (memberData.length === 0) {
      console.error("No members found for the provided companies");
      return;
    }

    const memberIds = memberData.map((member) => member.id);
    const rows = await constructMemberExportRows(memberIds);

    const regular = rows.filter(
      (row) =>
        row["Employment Status"] !== "terminated" &&
        row["Eligibility"] !== false
    );
    const terminated = rows.filter(
      (row) => row["Employment Status"] === "terminated"
    );
    const ineligible = rows.filter((row) => row["Eligibility"] === false);

    // Create a new workbook
    const workbook = xlsx.utils.book_new();

    // Create worksheets for each category
    const worksheets = {
      "All Members": xlsx.utils.json_to_sheet(rows, { header: BCBS_HEADERS }),
      Regular: xlsx.utils.json_to_sheet(regular, { header: BCBS_HEADERS }),
      Terminated: xlsx.utils.json_to_sheet(terminated, {
        header: BCBS_HEADERS,
      }),
      ineligible: xlsx.utils.json_to_sheet(ineligible, {
        header: BCBS_HEADERS,
      }),
    };

    // Add worksheets to the workbook
    Object.entries(worksheets).forEach(([sheetName, worksheet]) => {
      xlsx.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    // Generate Excel file
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the file
    saveAs(data, `${csvTitle}.xlsx`);
  } catch (error) {
    console.error("Error in BulkMemberExporter:", error);
  }
};

export default BulkMemberExporter;
