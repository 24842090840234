/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import PropTypes from "prop-types";
import { SelectInput, FormDataConsumer } from "react-admin";
import createValidationFunctionArray from "../../../../lib/Validations";
import { FormattedNumberInput } from "../FormattedNumberInput";

const CONTRIBUTION_BASES = [
  { name: "Flat Amount", id: "flat_amount" },
  { name: "Percent", id: "percent" },
];
const PERCENT_STYLE = {
  position: "relative",
  top: "7px",
  right: "73px",
};
const CURRENCY_STYLE = {
  position: "relative",
  top: "7px",
  left: "12px",
};

const ContributionInputComponent = function ContributionInputComponent({
  attribute,
}) {
  const { label, source, validations } = attribute;

  return (
    <span>
      <SelectInput
        label={`${label} Basis`}
        validate={createValidationFunctionArray(validations)}
        key={`${source}_basis`}
        source={`${source}_basis`}
        choices={CONTRIBUTION_BASES}
        defaultValue="percent"
        style={{ paddingRight: "10px" }}
      />
      <FormDataConsumer>
        {({ formData }) => {
          const numberFieldType =
            formData[`${source}_basis`] === "flat_amount"
              ? "currency"
              : "percent";

          return (
            <span key={`${source}_amount`}>
              {numberFieldType === "currency" && (
                <span style={CURRENCY_STYLE}>$</span>
              )}
              <FormattedNumberInput
                label={`${label} Amount`}
                source={`${source}_amount`}
                formatter={numberFieldType}
                attribute={attribute}
              />
              {numberFieldType === "percent" && (
                <span style={PERCENT_STYLE}>%</span>
              )}
            </span>
          );
        }}
      </FormDataConsumer>
    </span>
  );
};

ContributionInputComponent.propTypes = {
  attribute: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
};

export default ContributionInputComponent;
