import React from "react";
import { Button, useNotify, useRecordContext } from "react-admin";
import CopyAllIcon from "@mui/icons-material/CopyAll";

const ClickToCopyCompanyIdComponent = (props) => {
  const notify = useNotify();
  const record = useRecordContext(props);
  const companyId = record.cdms_canonical_id;

  const onClick = () => {
    navigator.clipboard.writeText(companyId);
    notify(`Copied to clipboard: ${companyId}`);
  };

  return (
    <div>
      <Button onClick={onClick} title={companyId}>
        ...{companyId.slice(-10)}
        <CopyAllIcon style={{ marginLeft: "5px" }} />
      </Button>
    </div>
  );
};

export const ClickToCopyCompanyId = function ClickToCopyCompanyId(props) {
  return (
    <ClickToCopyCompanyIdComponent
      key="ClickToCopyCompanyIdComponent"
      {...props}
    />
  );
};
