import { useState } from "react";
import {
  Button,
  Confirm,
  useRecordContext,
  useNotify,
  useRefresh,
} from "react-admin";
import { useTakeAction } from "../../../../lib/Hooks";

const ResetHealthApplicationComponent = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const id = record.id;
  const method = "POST";
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const refresh = useRefresh();
  const handleConfirm = () => {
    takeAction();
    setOpen(false);
  };
  const [takeAction] = useTakeAction(
    "company_insurance_quote_requests",
    { id, subAction: "reset_quote_conversion" },
    {
      method,
      onSuccess: () => {
        refresh();
        notify(`Quote Request ${id} has been successfully reset.`, {
          type: "success",
        });
      },
      onError: (response) => {
        notify(response.body.error, { type: "error" });
      },
    }
  );

  return (
    <>
      <Button label="Reset Quote Request" onClick={handleClick} />
      <Confirm
        isOpen={open}
        title="Reset Quote Request"
        content={`Are you sure you want to reset quote request ${record.id}? This will delete ALL plans related to the company benefit offering`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

function ResetHealthApplication() {
  return <ResetHealthApplicationComponent />;
}

export default ResetHealthApplication;
