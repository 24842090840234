import * as React from "react";

import { useRecordContext } from "react-admin";
import { DocumentDownloadButton } from "../DocumentDownloadButton";

const DocumentDownloadComponent = function DocumentDownloadComponent(props) {
  const recordContext = useRecordContext(props);
  const documentId = recordContext.id;
  const fileName = recordContext.file_name;

  return <DocumentDownloadButton documentId={documentId} fileName={fileName} />;
};

const DocumentDownloadTab = function DocumentDownloadTab() {
  return <DocumentDownloadComponent key="DocumentDownloadComponent" />;
};

export default DocumentDownloadTab;
