import * as Choices from "../../SelectChoices";

const id = {
  source: "id",
  label: "ID",
  exclude: ["Create", "Edit", "List"],
};
const member_id = {
  source: "id",
  label: "Member ID",
  sortable: true,
  reference: [["members", "member_id"]],
  type: "string",
  exclude: ["Create", "Edit"],
};

const event_type = {
  source: "event_type",
  label: "Event Type",
  choices: Choices.OPTUM_EVENT_TYPES,
  type: "drop-down",
  validations: { required: true },
};

const effective_on = {
  source: "effective_on",
  label: "Effective On",
  sortable: true,
  type: "date",
  validations: { required: true },
};

export default {
  top_level_resource_config: {
    title: "Optum Events",
    backend_resource: "optum_events",
    attributes: [id, member_id, event_type, effective_on],
    components: ["Show", "Create", "Edit"],
  },
  sub_resource_config: {
    tab_name: "Optum Events",
    backend_resource: "optum_events",
    linking_field: "company_id",
    attributes: [id, member_id, event_type, effective_on],
    components: ["Show", "Create", "List", "Edit"],
  },
};
