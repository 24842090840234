import * as React from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../ProcessManager";

const MemberHealthInsuranceSetupManagerComponent =
  function MemberHealthInsuranceSetupManagerComponent(props) {
    const memberId = useRecordContext(props).id;

    return (
      <ProcessManager
        processName="member_health_insurance_setup"
        ownerId={memberId}
      />
    );
  };

const MemberHealthInsuranceSetupManager =
  function MemberHealthInsuranceSetupManager() {
    return (
      <MemberHealthInsuranceSetupManagerComponent
        label="Member Selection Window Setup"
        key="MemberHealthInsuranceSetupManagerComponent"
      />
    );
  };

export default MemberHealthInsuranceSetupManager;
