import { React } from "react";
import { useRecordContext, Loading, SimpleShowLayout } from "react-admin";
import CompositeDataGrid from "./CompositeDataGrid";
import AgeBandedDataGrid from "./AgeBandedDataGrid";

const NO_PRICE_GRID_MESSAGE = (
  <p>No price grid associated with this Plan Offering</p>
);

const RateGrid = function () {
  const priceGrid = useRecordContext();

  if (!priceGrid) {
    return <Loading />;
  }

  // Convert the rates from integers to decimal
  const { rates, rate_type: rateType } = priceGrid;
  const formattedRates = {};
  Object.keys(rates).forEach((tier) => {
    formattedRates[tier] = rates[tier] / 100;
  });

  switch (rateType) {
    case "composite":
      return <CompositeDataGrid rates={formattedRates} />;
    case "age_banded":
      return <AgeBandedDataGrid rates={formattedRates} />;
    default:
      return NO_PRICE_GRID_MESSAGE;
  }
};

const PriceGrid = function () {
  return (
    <SimpleShowLayout>
      <RateGrid key="RateGrid" />
    </SimpleShowLayout>
  );
};

export default PriceGrid;
