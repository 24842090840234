import React from "react";

import { RecordContextProvider, SimpleShowLayout } from "react-admin";

import { Paper, Grid } from "@mui/material";

import { WorkflowActionButton } from "../../../WorkflowActionButton";

const NoyoObjectImporter = function NoyoObjectImporter({
  noyoObject,
  resource,
  children,
}) {
  return (
    <RecordContextProvider value={noyoObject}>
      <Paper sx={{ padding: "1em", margin: "1em" }}>
        <Grid container spacing={0}>
          <Grid item container direction="column" sx={{ width: "350px" }}>
            <Grid item container>
              <SimpleShowLayout>{children}</SimpleShowLayout>
            </Grid>
          </Grid>
          <Grid item>
            <WorkflowActionButton
              id={noyoObject.id}
              action="import_from_noyo"
              successMsg={() => "Record imported from Noyo"}
              resource={resource}
              label="Import From Noyo"
              sx={{ paddingTop: "8px" }}
            />
          </Grid>
        </Grid>
      </Paper>
    </RecordContextProvider>
  );
};

export default NoyoObjectImporter;
