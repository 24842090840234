import * as React from "react";

import PropTypes from "prop-types";
import DownloadButton from "../../DownloadButton";

import { BASE_URL } from "../../../../../lib/constants";

const DocumentDownloadButton = function DocumentDownloadButton({
  documentId,
  fileName,
}) {
  const url = `${BASE_URL}/documents/${documentId}/file`;

  return <DownloadButton label="Download" url={url} fileName={fileName} />;
};

DocumentDownloadButton.propTypes = {
  documentId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default DocumentDownloadButton;
