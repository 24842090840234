/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import { useRecordContext, useGetManyReference } from "react-admin";

const QuotesTabComponent = function QuotesTabComponent(props) {
  const censusId = useRecordContext(props).id;
  const {
    data: quotes,
    isLoading,
    error,
  } = useGetManyReference("company_insurance_quotes", {
    target: "company_census_id",
    id: censusId,
    sort: { field: "published_at", order: "DESC" },
  });
  if (isLoading) {
    return <p>Loading</p>;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <div>
      {quotes.map((quote, index) => {
        return (
          <div>
            <h3> Quote {index + 1} </h3>
            <p> Effective Date: {quote.effective_date} </p>
            <hr />
            <h4> Price Grid Rates </h4>
            <hr />
            <pre>{JSON.stringify(quote.plan_rates, null, 2)}</pre>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

const QuotesTab = function QuotesTab() {
  return <QuotesTabComponent />;
};

export default QuotesTab;
