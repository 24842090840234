/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Create, SimpleForm } from "react-admin";

import { Attribute } from "../GenericAttributes";

const GenericCreate = function GenericCreate(config) {
  return function CreateComponent(props) {
    return (
      <Create {...props} redirect="show">
        <SimpleForm>
          {config.attributes.map((attribute) => Attribute("Create", attribute))}
        </SimpleForm>
      </Create>
    );
  };
};

export default GenericCreate;
