import { React, useContext } from "react";
import { SelectInput, required, FormDataConsumer } from "react-admin";
import PropTypes from "prop-types";

import { EmployeeEnrollComponent } from "../EmployeeEnrollComponent";
import { EmployeeDisenrollComponent } from "../EmployeeDisenrollComponent";
import { DependentEnrollComponent } from "../DependentEnrollComponent";
import { DependentDisenrollComponent } from "../DependentDisenrollComponent";

import { QleSubmissionFormContext } from "../../context";

const returnComponentForAction = function returnComponentForAction(
  formData,
  benefitType,
  enrolledDependentIds
) {
  const action = formData.selected_actions[benefitType];

  switch (action) {
    case "employee_enroll":
      return <EmployeeEnrollComponent benefitType={benefitType} />;
    case "employee_disenroll":
      return <EmployeeDisenrollComponent benefitType={benefitType} />;
    case "dependent_enroll":
      return (
        <DependentEnrollComponent
          benefitType={benefitType}
          enrolledDependentIds={enrolledDependentIds}
        />
      );
    case "dependent_disenroll":
      return (
        <DependentDisenrollComponent
          benefitType={benefitType}
          enrolledDependentIds={enrolledDependentIds}
        />
      );
    default:
      return null;
  }
};

const SelectedActionComponent = function SelectedActionComponent(props) {
  const { availableActions, benefitType } = props;

  const { currentEnrollmentData, currentOfferingData } = useContext(
    QleSubmissionFormContext
  );

  const enrollmentData = currentEnrollmentData[benefitType] || {};
  const { allOfferings, allDependents } = currentOfferingData;
  const enrolledDependentIds = enrollmentData.enrolledDependentIds || [];
  const nonEnrolledDependents = Object.values(allDependents).filter(
    (dependent) => !enrolledDependentIds.includes(dependent.id)
  );

  const filteredActions = availableActions.filter((action) => {
    const hasEnrollment = Object.entries(enrollmentData).length > 0;
    const alternativeOfferings = allOfferings[benefitType].filter(
      (offering) => offering.id !== enrollmentData.planOfferingId
    );

    switch (action.id) {
      case "employee_enroll":
        return action.available && !!alternativeOfferings[0];
      case "employee_disenroll":
        return action.available && hasEnrollment;
      case "dependent_enroll":
        return action.available && hasEnrollment && !!nonEnrolledDependents[0];
      case "dependent_disenroll":
        return action.available && hasEnrollment && !!enrolledDependentIds[0];
      default:
        return true;
    }
  });

  const actionChoices = [
    { id: "no_action", name: "No action" },
    ...filteredActions,
  ];

  return (
    <div>
      <h3>{`Select action for ${benefitType} coverage.`}</h3>
      <SelectInput
        label="Requested Action"
        source={`selected_actions.${benefitType}`}
        validate={required()}
        choices={actionChoices}
      />
      <br />
      <FormDataConsumer>
        {({ formData }) =>
          returnComponentForAction(formData, benefitType, enrolledDependentIds)
        }
      </FormDataConsumer>
    </div>
  );
};

SelectedActionComponent.propTypes = {
  availableActions: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool]))
  ).isRequired,
  benefitType: PropTypes.string.isRequired,
};

export default SelectedActionComponent;
