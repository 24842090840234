import * as React from "react";
import PropTypes from "prop-types";
import {
  CheckboxGroupInput,
  useRecordContext,
  useGetList,
  LinearProgress,
} from "react-admin";
import createValidationFunctionArray from "../../../../lib/Validations";
import { useParentRecordContext } from "../../contexts";

const MultiCheckboxComponent = function MultiCheckboxComponent({ attribute }) {
  const { label, source, validations, options_reference } = attribute;
  const [referenceResource, referenceFieldName] = options_reference;
  const filterParams = attribute.filter_params;
  const [sortField = "created_at", sortOrder = "DESC"] =
    attribute.sort_params || [];
  const recordValues = useRecordContext() || {};
  const parentRecordContext = useParentRecordContext();
  let filter;

  if (filterParams) {
    /* eslint-disable no-param-reassign */
    filter = filterParams.reduce((result, paramPairs) => {
      const [filterKey, recordKey] = paramPairs;
      result[filterKey] =
        recordValues[recordKey] || parentRecordContext[recordKey];
      return result;
    }, {});
    /* eslint-enable no-param-reassign */
  }

  const { data: choices, isLoading } = useGetList(referenceResource, {
    perPage: 10000,
    sort: { field: sortField, order: sortOrder },
    filter: filter,
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <CheckboxGroupInput
      label={label}
      source={source}
      filter={filter}
      validate={createValidationFunctionArray(validations)}
      choices={choices}
      optionText={referenceFieldName}
      optionValue={referenceFieldName}
    />
  );
};

MultiCheckboxComponent.propTypes = {
  attribute: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ).isRequired,
};

export default MultiCheckboxComponent;
