import React from "react";
import PropTypes from "prop-types";
import {
  useNotify,
  useRefresh,
  LinearProgress,
  Error,
  Button,
} from "react-admin";

import { useTakeAction } from "../../../../../../lib/Hooks";

const WorkflowActionButton = function WorkflowActionButton({
  id,
  action,
  subAction,
  successMsg,
  resource,
  label,
  postBody,
  ...rest
}) {
  const refresh = useRefresh();
  const notify = useNotify();
  const successNotification = successMsg
    ? successMsg(id)
    : `Action taken for run ${id}`;

  const [takeAction, { isLoading, error }] = useTakeAction(
    resource,
    { id, action, subAction },
    {
      body: JSON.stringify(postBody),
      onSuccess: () => notify(successNotification, { type: "success" }),
      onError: (response) => {
        notify(response.body.error, { type: "error" });
      },
      onSettled: () => refresh(),
    }
  );

  if (isLoading) return <LinearProgress />;
  if (error) return <Error />;

  const handleClick = (e) => {
    e.preventDefault();
    takeAction();
  };

  return (
    <Button
      label={`${label || subAction || action}!`}
      onClick={handleClick}
      {...rest}
    />
  );
};

WorkflowActionButton.propTypes = {
  id: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  subAction: PropTypes.string,
  successMsg: PropTypes.func,
  resource: PropTypes.string,
  label: PropTypes.string,
  postBody: PropTypes.object,
};

WorkflowActionButton.defaultProps = {
  successMsg: null,
  subAction: null,
  resource: "workflow_runs",
  label: null,
  postBody: {},
};

export default WorkflowActionButton;
