import { React } from "react";
import PropTypes from "prop-types";
import { CheckboxGroupInput, DateInput, required } from "react-admin";

const EmployeeDisenrollComponent = function EmployeeDisenrollComponent(props) {
  const { benefitType } = props;

  const choices = [
    {
      id: "remove_coverage",
      name: `Remove ${benefitType} coverage for member and all dependents?`,
    },
  ];
  return (
    <div>
      <h4 style={{ textTransform: "capitalize" }}>{benefitType}</h4>
      <DateInput
        label="Coverage End Date"
        source={`coverage_modifications.${benefitType}.action_details.coverage_end_date`}
        validate={required()}
      />
      <br />
      <CheckboxGroupInput
        label=""
        source={`coverage_modifications.${benefitType}.available_action_type`}
        choices={choices}
        validate={required()}
      />
    </div>
  );
};

EmployeeDisenrollComponent.propTypes = {
  benefitType: PropTypes.string.isRequired,
};

export default EmployeeDisenrollComponent;
