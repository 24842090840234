import React from "react";

import {
  FunctionField,
  TextField,
  Button,
  SimpleShowLayout,
  Labeled,
  CreateBase,
  SimpleForm,
  SelectInput,
  LinearProgress,
  SaveButton,
  useGetList,
  useCreate,
  useRefresh,
  useNotify,
  required,
} from "react-admin";

import { Typography, Grid, Paper } from "@mui/material";

const PlanSelectItem = function PlanSelectItem() {
  return (
    <FunctionField
      render={({ display_name: displayName, name: planCode, year }) =>
        `${displayName} - ${planCode} - ${year}`
      }
    />
  );
};

const PlanOfferingCreator = function PlanOfferingCreator({
  groupPlan,
  groupEnrollmentToPlanYearIdMap,
  offeredProviderIds,
  companyId,
}) {
  const {
    id: groupPlanId,
    carrier_id: carrierId,
    line_of_coverage: lineOfCoverage,
    group_enrollment_id: groupEnrollmentId,
    name: displayName,
    code: planCode,
  } = groupPlan;
  const { [groupEnrollmentId]: planYearId } = groupEnrollmentToPlanYearIdMap;

  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { isLoading: createLoading }] = useCreate();
  const onError = (error) => {
    refresh();
    notify(error.message, { type: "error" });
  };
  const mapGroupPlanId = (planOfferingId, groupPlanId) => {
    create(
      "external_id_mappings",
      {
        data: {
          internal_id: planOfferingId,
          internal_resource: "company_plan_offerings",
          external_id: groupPlanId,
          external_id_owner: "noyo",
        },
      },
      {
        onSuccess: () => {
          refresh();
          notify("Plan offering created.", { type: "success" });
        },
      }
    );
  };
  const handlePlanOfferingCreate = (data) => {
    mapGroupPlanId(data.id, groupPlanId);
  };
  const { data: providers = [], isLoading: providersLoading } = useGetList(
    "providers",
    {
      filter: {
        external_id_mappings: {
          external_id: carrierId,
          external_id_owner: "noyo",
        },
      },
    }
  );
  const provider = providers[0];

  const { data: planList = [], isLoading: plansLoading } = useGetList("plans", {
    pagination: { page: 1, perPage: 1000 },
    filter: {
      provider_id: offeredProviderIds,
      benefit_type: lineOfCoverage,
      plan_year_id: planYearId,
    },
  });

  const createPlan = (onSuccess) => {
    create(
      "plans",
      {
        data: {
          plan_year_id: planYearId,
          benefit_type: lineOfCoverage,
          provider_id: provider.id,
          enrollment_method: "noyo",
          name: planCode,
          display_name: displayName,
        },
      },
      { onSuccess, onError }
    );
  };

  const createPlanOffering = (planId, onSuccess) => {
    create(
      "company_plan_offerings",
      {
        data: {
          plan_id: planId,
          company_id: companyId,
          group_enrollment_id: groupEnrollmentId,
          selection_status: "confirmed",
        },
      },
      { onSuccess, onError }
    );
  };

  const createPlanAndOffering = () => {
    createPlan(({ id: planId }) => {
      createPlanOffering(planId, ({ id: planOfferingId }) => {
        mapGroupPlanId(planOfferingId, groupPlanId);
      });
    });
  };

  if (plansLoading || providersLoading || createLoading)
    return <LinearProgress />;

  return (
    <Paper sx={{ padding: "1em", margin: "1em" }}>
      <Typography>Noyo Group Plan</Typography>
      <Grid container direction="row">
        <Grid item xs={4}>
          <SimpleShowLayout record={groupPlan}>
            <Labeled>
              <Typography label="Provider">{provider.name}</Typography>
            </Labeled>
            <TextField label="Display Name" source="name" />
            <TextField label="Plan Code" source="code" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={8}>
          <CreateBase
            resource="company_plan_offerings"
            redirect={false}
            actions={null}
            mutationOptions={{ onSuccess: handlePlanOfferingCreate }}
          >
            <SimpleForm
              defaultValues={{
                company_id: companyId,
                group_enrollment_id: groupEnrollmentId,
                plan_year_id: planYearId,
                selection_status: "confirmed",
              }}
              toolbar={false}
              sx={{ padding: 0 }}
            >
              <SelectInput
                label="Select Plan For Offering"
                source="plan_id"
                validate={required()}
                choices={planList}
                optionText={<PlanSelectItem />}
              />
              <SaveButton label="Create Plan Offering For Selected Plan" />
            </SimpleForm>
            <Button
              color="secondary"
              label="Create New Plan And Plan Offering"
              onClick={createPlanAndOffering}
            />
          </CreateBase>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PlanOfferingCreator;
