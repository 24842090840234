import { useState } from "react";

const useToggle = () => {
  const [state, setState] = useState(false);
  const toggleState = () => {
    setState(!state);
  };
  return [state, toggleState, setState];
};

export default useToggle;
