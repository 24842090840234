import { React } from "react";
import { useRecordContext } from "react-admin";
import {
  dentalAttributes,
  medicalAttributes,
  visionAttributes,
} from "./planMetadataAttributes";
import PlanMetadataShowAttributes from "./components/PlanMetadataShowAttributes";

const PlanMetadataShowComponent = function PlanMetadataShowComponent(props) {
  const benefit_type = useRecordContext(props).benefit_type;

  if (benefit_type === "medical") {
    return (
      <PlanMetadataShowAttributes
        key="MedicalPlanMetadataAttributes"
        attributes={medicalAttributes}
      />
    );
  } else if (benefit_type === "dental") {
    return (
      <PlanMetadataShowAttributes
        key="DentalPlanMetadataAttributes"
        attributes={dentalAttributes}
      />
    );
  } else {
    return (
      <PlanMetadataShowAttributes
        key="VisionPlanMetadataAttributes"
        attributes={visionAttributes}
      />
    );
  }
};

const PlanMetadataShow = function PlanMetadataShow() {
  return <PlanMetadataShowComponent key="PlanMetadataShowComponent" />;
};

export default PlanMetadataShow;
