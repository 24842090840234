import {
  login,
  checkAuth,
  logout,
  getIdentity,
  checkError,
} from "./AuthProviderHelpers";

const AuthProvider = {
  login,
  checkError,
  checkAuth,
  logout,
  getIdentity,
  // authorization
  getPermissions: () => Promise.resolve(),
};

export default AuthProvider;
