/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import { QleSubList } from "./components";
import { QleSubmissionForm } from "./components/QleSubmissionForm";

const MemberQleTab = function MemberQleTab() {
  return (
    <div>
      <QleSubList />
      <QleSubmissionForm />
    </div>
  );
};

export default MemberQleTab;
