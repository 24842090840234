import { React } from "react";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import { NumberInput, required } from "react-admin";

const DentalAndVisionFormComponent = () => {
  return (
    <Box sx={{ display: "grid" }}>
      <NumberInput
        label="Group Size"
        source="group_size"
        validate={required()}
      />
    </Box>
  );
};

const MedicalFormComponent = () => {
  return (
    <NumberInput
      label="Zywave Quote ID"
      source="quote_id"
      validate={required()}
    />
  );
};

const BenefitSpecificComponent = () => {
  const { watch } = useFormContext();
  const selectedBenefitTypes = watch("benefit_types");
  if (!selectedBenefitTypes) {
    return null;
  }

  const medical = selectedBenefitTypes?.includes("medical");
  const dentalOrVision =
    selectedBenefitTypes?.includes("dental") ||
    selectedBenefitTypes?.includes("vision");

  return (
    <Box sx={{ display: "grid" }}>
      {medical && <MedicalFormComponent />}
      {dentalOrVision && <DentalAndVisionFormComponent />}
    </Box>
  );
};

export default BenefitSpecificComponent;
