import { React } from "react";
import { Show, SimpleShowLayout } from "react-admin";
import Fields from "./components/Fields";

const DocumentRequirementShow = function DocumentRequirementShow() {
  return (
    <Show redirect="show">
      <SimpleShowLayout>{Fields.map((field) => field)}</SimpleShowLayout>
    </Show>
  );
};

export default DocumentRequirementShow;
