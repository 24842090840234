import React from "react";

import {
  Datagrid,
  TextField,
  ShowButton,
  FunctionField,
  ResourceContextProvider,
} from "react-admin";

import MemberDependentSetupManager from "../../../../Resources/Dependents/MemberDependentSetupManager";

const fullName = (record) => `${record.last_name}, ${record.first_name}`;
const sort = { field: "last_name", order: "ASC" };

const WaitingForDependentSetupCompletionPrompt =
  function WaitingForDependentSetupCompletionPrompt({ data }) {
    const { dependents } = data;

    return (
      <div>
        {dependents[0] && (
          <div>
            <h4>Dependent setup status:</h4>
            <ResourceContextProvider value="member_dependents">
              <Datagrid data={dependents} sort={sort}>
                <FunctionField label="Name" render={fullName} />
                <TextField source="relationship" />
                <MemberDependentSetupManager />
                <ShowButton />
              </Datagrid>
            </ResourceContextProvider>
          </div>
        )}
      </div>
    );
  };

export default WaitingForDependentSetupCompletionPrompt;
