/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Loading, Error, useRecordContext, useGetList } from "react-admin";

import { ExternalIdMappingForm } from "./components";

const ExternalIdMappingTab = function ExternalIdMappingTab(config) {
  const { backend_resource: resource, mapped_to: validOwners } = config;

  const ExternalIdMappingTabComponent =
    function ExternalIdMappingTabComponent() {
      const { id: parentId } = useRecordContext();

      const { data, isLoading, isError } = useGetList(
        "external_id_mappings",
        {
          filter: { internal_id: parentId },
        },
        {
          enabled: !!parentId,
        }
      );

      if (isLoading) return <Loading />;
      if (isError) return <Error />;

      return (
        <div>
          {validOwners.map((owner) => {
            const record = data.find(
              (mapping) => mapping.external_id_owner === owner
            ) || {
              internal_id: parentId,
              internal_resource: resource,
              external_id_owner: owner,
            };

            return <ExternalIdMappingForm key={owner} currentRecord={record} />;
          })}
        </div>
      );
    };

  return ExternalIdMappingTabComponent;
};
export default ExternalIdMappingTab;
