import * as React from "react";

import { useRecordContext } from "react-admin";
import { DocumentDownloadButton } from "../../DocumentTab/DocumentDownloadButton";

const CompanyCarrierInstallDocumentDownloadComponent =
  function CompanyCarrierInstallDocumentDownloadComponent(props) {
    const recordContext = useRecordContext(props);
    const documentId = recordContext.document_id;
    const fileName = recordContext.file_name;

    return (
      <DocumentDownloadButton documentId={documentId} fileName={fileName} />
    );
  };

const CompanyCarrierInstallDocumentDownloadTab =
  function CompanyCarrierInstallDocumentDownloadTab() {
    return (
      <CompanyCarrierInstallDocumentDownloadComponent key="CompanyCarrierInstallDocumentDownloadComponent" />
    );
  };

export default CompanyCarrierInstallDocumentDownloadTab;
