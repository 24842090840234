import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import {
  useRecordContext,
  useResourceContext,
  TextField,
  useGetOne,
} from "react-admin";
import { LinearProgress } from "@mui/material";

const MaskedSsnToggleComponent = (props) => {
  const { label, source, sortable } = props;
  const record = useRecordContext();
  const resource = useResourceContext();

  const [masked, updateMasked] = useState(true);
  const { data = {}, isLoading } = useGetOne(
    resource,
    { id: record.id, meta: { unmask_secrets: true } },
    { enabled: !masked }
  );

  const unmask = () => {
    updateMasked(false);
  };

  if (isLoading) return <LinearProgress />;
  return (
    <div>
      <TextField
        label={label}
        key={label}
        source={source}
        record={{ ssn: data.ssn || record.ssn }}
        sortable={!!sortable}
      />
      <Button onClick={unmask}>Reveal</Button>
    </div>
  );
};

const MaskedSsnToggle = function MaskedSsnToggle(props) {
  return <MaskedSsnToggleComponent key="MaskedSsnToggleComponent" {...props} />;
};
MaskedSsnToggleComponent.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sortable: PropTypes.bool,
};

MaskedSsnToggleComponent.defaultProps = {
  sortable: true,
};

export default MaskedSsnToggle;
