import React from "react";

import {
  DateField,
  FunctionField,
  TextField,
  ResourceContextProvider,
} from "react-admin";

import { MatchResolver, NoyoObjectImporter } from "./components";

import { explainerStyle } from "./styles";

const fullName = (record) =>
  `${record.last_name}, ${record.first_name}${
    record.middle_name ? ` ${record.middle_name}` : ""
  }`;

const ResolveNoyoDependentMismatches = function ResolveNoyoDependentMismatches({
  data,
}) {
  const {
    unmapped_sage_objects: unmappedSageObjects,
    unmapped_noyo_objects: unmappedNoyoObjects,
  } = data;

  const noyoChoices = unmappedNoyoObjects.map((dependent) => ({
    name: fullName(dependent.person),
    ...dependent,
  }));

  return (
    <div>
      {unmappedSageObjects[0] && (
        <div style={explainerStyle}>
          Match each of the listed dependents to a Noyo record or if you are
          sure a new record should be created click 'Create In Noyo'
        </div>
      )}
      <ResourceContextProvider value="dependents">
        {unmappedSageObjects.map((sageObject) => (
          <MatchResolver
            key={sageObject.id}
            sageObject={sageObject}
            noyoChoices={noyoChoices}
          >
            <FunctionField label="Full Name" render={fullName} />
            <DateField source="date_of_birth" />
            <TextField source="relationship" />
          </MatchResolver>
        ))}
      </ResourceContextProvider>

      {!unmappedSageObjects[0] && unmappedNoyoObjects && (
        <div style={explainerStyle}>
          There are no unmapped Sage objects, but the following extra objects
          exist in Noyo. Import them or click 'Skip remaining' to indicate we
          don't need them.
        </div>
      )}
      {!unmappedSageObjects[0] &&
        unmappedNoyoObjects.map((noyoObject) => (
          <NoyoObjectImporter
            key={noyoObject.id}
            noyoObject={noyoObject}
            resource="dependents"
          >
            <FunctionField label="Full Name" render={fullName} />
            <DateField source="date_of_birth" />
            <TextField source="relationship" />
          </NoyoObjectImporter>
        ))}
    </div>
  );
};

export default ResolveNoyoDependentMismatches;
