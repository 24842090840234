import * as React from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../ProcessManager";

const CompanyMedicalInsuranceSetupComponent =
  function CompanyMedicalInsuranceSetupComponent(props) {
    const companyProviderConnectionId = useRecordContext(props).id;

    return (
      <div>
        <p>Initial Stetup</p>
        <ProcessManager
          processName="company_medical_insurance_setup"
          ownerId={companyProviderConnectionId}
          kickoffButtonLabel="Start Setup"
        />
        <p>Renewals Cycle</p>
        <ProcessManager
          processName="company_medical_insurance_renewal"
          ownerId={companyProviderConnectionId}
          kickoffButtonLabel="Initiate Renewals Cycle"
        />
      </div>
    );
  };

const CompanyMedicalInsuranceSetupManager =
  function CompanyMedicalInsuranceSetupManager() {
    return (
      <CompanyMedicalInsuranceSetupComponent
        label="Company Noyo Setup"
        key="CompanyMedicalInsuranceSetupComponent"
      />
    );
  };

export default CompanyMedicalInsuranceSetupManager;
