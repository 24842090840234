import { React } from "react";
import { List, Datagrid, ShowButton, EditButton, TextInput } from "react-admin";
import Fields from "./components/Fields";
import { FilterInputs } from "./components/Inputs";

const DocumentRequirementList = function DocumentRequirementList() {
  return (
    <div>
      <List
        resource="company_carrier_install_document_requirement_rules"
        filters={[
          <TextInput key="rule_name_query" source="query" />,
          ...FilterInputs,
        ]}
      >
        <Datagrid>
          {Fields.map((field) => field)}
          <ShowButton />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  );
};

export default DocumentRequirementList;
