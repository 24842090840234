import React from "react";

import {
  CreateBase,
  List,
  Datagrid,
  SimpleShowLayout,
  Form,
  ReferenceField,
  SingleFieldList,
  ChipField,
  FunctionField,
  SelectInput,
  ReferenceInput,
  TextField,
  ShowButton,
  SaveButton,
  CreateButton,
  ReferenceArrayField,
  Labeled,
  useRefresh,
  useNotify,
  useGetList,
  useRecordContext,
  required,
} from "react-admin";

import { useFormContext } from "react-hook-form";
import { Typography, Grid, Paper } from "@mui/material";

import { COMPANY_PLAN_OFFERING_SELECTION_STATUS } from "../../SelectChoices";

const sort = { field: "id", order: "ASC" };

const SaveAndEmptyFormButton = function SaveAndEmptyFormButton() {
  const { reset } = useFormContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const onSuccess = () => {
    reset();
    notify("Created", { type: "success" });
    refresh();
  };

  const onError = (error) => {
    reset();
    notify(error.message, { type: "error" });
    refresh();
  };

  return (
    <SaveButton
      label="Add"
      type="button"
      mutationOptions={{ onSuccess, onError }}
    />
  );
};

const PlanSelectItem = function PlanSelectItem({ providerNames }) {
  return (
    <FunctionField
      render={({ provider_id, display_name: displayName, name }) =>
        `${providerNames.get(provider_id)} - ${displayName || name}`
      }
    />
  );
};

const CreatePlanOfferingsForBenefitOffering =
  function CreatePlanOfferingsForBenefitOffering() {
    const { data: providers, isLoading } = useGetList("providers");
    const benefitOffering = useRecordContext();

    if (isLoading) return null;

    const providerNames = new Map(providers.map(({ id, name }) => [id, name]));

    return (
      <SimpleShowLayout>
        <Paper sx={{ padding: "1em" }}>
          <Grid container direction="column" spacing={0}>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Labeled>
                  <TextField source="display_name" label="Benefit Offering" />
                </Labeled>
              </Grid>
              <Grid item xs={5}>
                <Labeled>
                  <ReferenceArrayField
                    source="provider_ids"
                    label="Providers"
                    reference="providers"
                    perpage={10000}
                    sort={sort}
                  >
                    <SingleFieldList linkType="show">
                      <ChipField size="small" source="name" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </Labeled>
              </Grid>
              <Grid item>
                <ShowButton />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Plan Offerings:</Typography>
              <List
                resource="company_plan_offerings"
                filter={{
                  company_id: benefitOffering.company_id,
                  plan_year_id: benefitOffering.plan_year_id,
                  company_benefit_offering_id: benefitOffering.id,
                }}
                actions={false}
                perPage={5}
                disableSyncWithLocation={true}
                empty={
                  <Typography variant="body2">
                    No plan offerings for this benefit offering.
                  </Typography>
                }
              >
                <Datagrid bulkActionButtons={false}>
                  <ReferenceField
                    label="Plan"
                    source="plan_id"
                    reference="plans"
                    perPage={10000}
                  >
                    <TextField source="display_name" />
                  </ReferenceField>
                  <ReferenceField
                    label="Plan Year"
                    source="plan_year_id"
                    reference="plan_years"
                    validation={required()}
                    perPage={10000}
                  >
                    <TextField source="year" />
                  </ReferenceField>
                  <TextField source="selection_status" />
                  <ShowButton />
                </Datagrid>
              </List>
            </Grid>
            <Grid item>
              <CreateBase
                resource="company_plan_offerings"
                actions={false}
                redirect={false}
                sx={{ border: "0" }}
              >
                <Form
                  toolbar={false}
                  defaultValues={{
                    company_benefit_offering_id: benefitOffering.id,
                    company_id: benefitOffering.company_id,
                    plan_year_id: benefitOffering.plan_year_id,
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    sx={{ height: "50px" }}
                  >
                    <Grid item>
                      <ReferenceInput
                        source="plan_id"
                        reference="plans"
                        filter={{
                          provider_id: benefitOffering.provider_ids,
                          plan_year_id: benefitOffering.plan_year_id,
                          benefit_type: benefitOffering.benefit_type,
                        }}
                        perPage={10000}
                        sort={{ field: "display_name", order: "ASC" }}
                        label="Plan"
                        sx={{ width: "100%" }}
                      >
                        <SelectInput
                          optionText={
                            <PlanSelectItem providerNames={providerNames} />
                          }
                        />
                      </ReferenceInput>
                      <SelectInput
                        source="selection_status"
                        choices={COMPANY_PLAN_OFFERING_SELECTION_STATUS}
                        helperText={false}
                      />
                      <CreateButton
                        target="_blank"
                        resource="plans"
                        label="New Plan"
                        sx={{ marginLeft: "10px", marginTop: "15px" }}
                      />
                    </Grid>
                    <Grid item sx={{ marginLeft: "10px", paddingTop: "8px" }}>
                      <SaveAndEmptyFormButton />
                    </Grid>
                  </Grid>
                </Form>
              </CreateBase>
            </Grid>
          </Grid>
        </Paper>
      </SimpleShowLayout>
    );
  };

export default CreatePlanOfferingsForBenefitOffering;
