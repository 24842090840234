const id = {
  source: "id",
  label: "Member Commuter Enrollment ID",
  sortable: true,
  reference: [["member_commuter_enrollments", "member_commuter_enrollment_id"]],
  filter_params: [["member_id", "member_id"]],
};
const amount = {
  source: "amount",
  validations: { required: true },
  label: "Amount",
  sortable: true,
  type: "number",
};
const order_type = {
  source: "order_type",
  label: "Order Type",
  sortable: true,
  type: "string",
};

const tax_category = {
  source: "tax_category",
  label: "Tax Category Date",
  sortable: true,
  type: "date",
};

const post_date = {
  source: "post_date",
  label: "Post Date",
  sortable: true,
  type: "date",
};

export default {
  top_level_resource_config: {
    title: "Member Commuter Orders",
    backend_resource: "member_commuter_orders",
    attributes: [id, amount, order_type, tax_category, post_date],
    components: ["Show"],
  },
  sub_resource_config: {
    tab_name: "Member Commuter Orders",
    backend_resource: "member_commuter_orders",
    linking_field: "member_commuter_enrollment_id",
    attributes: [id, amount, order_type, tax_category, post_date],
    components: ["Show", "List"],
  },
};
