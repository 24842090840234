import { React } from "react";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import { required, useGetList, SelectInput } from "react-admin";

const MedicalFormComponent = ({ zipCode }) => {
  const {
    data: formCounties,
    isLoading: countiesLoading,
    isError: countiesError,
  } = useGetList(
    `company_insurance_quote_requests/counties_from_zip/${zipCode}`,
    {},
    { enabled: zipCode?.length === 5 }
  );

  return (
    <Box sx={{ display: "grid" }}>
      <SelectInput
        label="County"
        source="county"
        choices={formCounties || []}
        validate={required()}
        disabled={countiesLoading || countiesError}
      />
    </Box>
  );
};

const BenefitSpecificComponent = () => {
  const { watch } = useFormContext();
  const selectedBenefitTypes = watch("benefit_types");
  const zipCode = watch("zip");

  if (!selectedBenefitTypes) {
    return null;
  }

  const medical = selectedBenefitTypes?.includes("medical");

  return (
    <Box sx={{ display: "grid" }}>
      {medical && <MedicalFormComponent zipCode={zipCode} />}
    </Box>
  );
};

export default BenefitSpecificComponent;
