import { React } from "react";

const EligibleMedicalCarriersForQuotingPrompt =
  function EligibleMedicalCarriersForQuotingPrompt({ data }) {
    const {
      quote_request_includes_medical,
      eligible_medical_carriers,
      census_has_rows,
    } = data;

    const eligibleMedicalCarriersDisplay = () => {
      if (eligible_medical_carriers.length === 0) {
        return "Group does not meet the requirements for any medical carrier";
      }

      return (
        <div>
          <p>{eligible_medical_carriers.join(", ")}</p>
        </div>
      );
    };

    if (census_has_rows === false) {
      return (
        <div>
          <p>
            This quote request does not have an uploaded census and will bypass
            checking for census eligibility.
          </p>
        </div>
      );
    }

    if (quote_request_includes_medical === false) {
      return (
        <div>
          <p>
            This quote request does not include medical and will bypass checking
            for census eligibility.
          </p>
        </div>
      );
    }

    return (
      <div>
        <h4>Eligible Medical Carriers</h4>
        {eligibleMedicalCarriersDisplay()}
      </div>
    );
  };

export default EligibleMedicalCarriersForQuotingPrompt;
