import React from "react";

import {
  Datagrid,
  ShowButton,
  FunctionField,
  ResourceContextProvider,
} from "react-admin";

import { MemberSetupManager } from "../../../MemberSetupManager";

const fullName = (record) => `${record.last_name}, ${record.first_name}`;
const sort = { field: "last_name", order: "ASC" };

const WaitingForMemberSetupCompletionPrompt =
  function WaitingForMemberSetupCompletionPrompt({ data }) {
    const { members } = data;

    return (
      <div>
        {members[0] && (
          <div>
            <h4>Member setup status:</h4>
            <ResourceContextProvider value="members">
              <Datagrid data={members} sort={sort}>
                <FunctionField label="Name" render={fullName} />
                <MemberSetupManager />
                <ShowButton />
              </Datagrid>
            </ResourceContextProvider>
          </div>
        )}
      </div>
    );
  };

export default WaitingForMemberSetupCompletionPrompt;
