/* eslint-disable default-param-last */
import { useMutation } from "react-query";
import { useDataProvider } from "react-admin";

const useTakeAction = (
  resource = undefined,
  { id, action, subAction } = {},
  options = {}
) => {
  const { takeAction } = useDataProvider();
  const { mutate, data, isLoading, isError } = useMutation(
    async ({
      resource: callTimeResource,
      id: callTimeId,
      action: callTimeAction,
      subAction: callTimeSubAction,
      options: callTimeOptions,
    } = {}) => {
      const params = {
        id: callTimeId || id,
        action: callTimeAction || action,
        subAction: callTimeSubAction || subAction,
      };
      const combinedOptions = {
        ...options,
        ...callTimeOptions,
      };

      return takeAction(callTimeResource || resource, params, combinedOptions);
    },
    {
      ...options,
      onSuccess: (value) => {
        // execute call-time onSuccess if provided
        if (options?.onSuccess) {
          options.onSuccess(value);
        }
      },
      onError: (value) => {
        // execute call-time onError if provided
        if (options?.onError) {
          options.onError(value);
        }
      },
    }
  );

  return [mutate, { data, isLoading, isError }];
};

export default useTakeAction;
