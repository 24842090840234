/* eslint-disable default-param-last */
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useDataProvider } from "react-admin";

const useGetProcessStatus = (processName, ownerId, options = {}) => {
  const dataProvider = useDataProvider();
  const result = useQuery(
    [processName, ownerId, "getProcessStatus"],
    () =>
      dataProvider
        .getProcessStatus(processName, ownerId)
        .then(({ data, total, pageInfo }) => ({
          data,
          total,
          pageInfo,
        })),
    {
      ...options,
      onSuccess: (value) => {
        // execute call-time onSuccess if provided
        if (options?.onSuccess) {
          options.onSuccess(value);
        }
      },
    }
  );

  return useMemo(
    () =>
      result.data
        ? {
            ...result,
            data: result.data?.data,
            total: result.data?.total,
            pageInfo: result.data?.pageInfo,
          }
        : result,
    [result]
  );
};

export default useGetProcessStatus;
