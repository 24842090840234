import * as React from "react";

import { useRecordContext } from "react-admin";
import { DocumentDownloadButton } from "../../DocumentTab/DocumentDownloadButton";

const PlanDocumentDownloadComponent = function PlanDocumentDownloadComponent(
  props
) {
  const recordContext = useRecordContext(props);
  const documentId = recordContext.document_id;
  const fileName = recordContext.file_name;

  return <DocumentDownloadButton documentId={documentId} fileName={fileName} />;
};

const PlanDocumentDownloadTab = function PlanDocumentDownloadTab() {
  return <PlanDocumentDownloadComponent key="PlanDocumentDownloadComponent" />;
};

export default PlanDocumentDownloadTab;
