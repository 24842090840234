/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import { Button, useRecordContext } from "react-admin";

const QleDashboardLinkComponent = function QleDashboardLinkComponent(props) {
  const qle = useRecordContext(props);

  return (
    <div>
      <Button href={`/dashboard/#/qualifying_life_events/${qle.id}`}>
        Show in Dashboard
      </Button>
    </div>
  );
};

const QleDashboardLink = function QleDashboardLink() {
  return <QleDashboardLinkComponent key="QleDashboardLinkComponent" />;
};

export default QleDashboardLink;
