/* eslint-disable react/jsx-no-bind */

import { React, useState, useMemo } from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  DateInput,
  SaveButton,
  Toolbar,
  required,
  useRecordContext,
  useGetList,
  Loading,
} from "react-admin";

import { QleSubmissionFormContext } from "./context";
import { SelectedActionComponent } from "./components";
import { formatCurrentEnrollmentData, formatOfferingData } from "./lib";
import { useInput } from "../../../../../../lib/Hooks";

const QLE_TYPES = [
  { id: "adoption", name: "Adoption" },
  { id: "dependent_aged_out", name: "Dependent Aged Out" },
  { id: "dependent_death", name: "Dependent Death" },
  {
    id: "dependent_moved_out_of_united_states",
    name: "Dependent Moved Out of the United States",
  },
  { id: "dependent_of_deceased", name: "Dependent of Deceased" },
  {
    id: "dependent_of_person_in_open_enrollment",
    name: "Dependent of Person in Open Enrollment",
  },
  { id: "dependent_open_enrollment", name: "Dependent in Open Enrollment" },
  { id: "domestic_partnership", name: "Domestic Partnership" },
  { id: "domestic_partnership_end", name: "End of Domestic Partnership" },
  { id: "marriage_end", name: "End of Marriage" },
  { id: "employer_paid_cobra_ended", name: "Employer Paid COBRA Ended" },
  { id: "gained_eligible_employment", name: "Gained Eligible Employment" },
  {
    id: "gained_individual_marketplace_plan",
    name: "Gained Individual Marketplace Plan",
  },
  { id: "gained_medicare", name: "Gained Medicare" },
  { id: "gained_outside_coverage", name: "Gained Outside Coverage" },
  {
    id: "gained_united_states_citizenship",
    name: "Gained United States Citizenship",
  },
  { id: "government_subsidy_ended", name: "Government Subsidy Ended" },
  { id: "address_changed_to_ineligible", name: "Ineligible Address Change" },
  { id: "legal_separation", name: "Legal Separation" },
  { id: "lost_cobra", name: "Lost COBRA" },
  { id: "lost_eligible_employment", name: "Lost Eligible Employment" },
  { id: "lost_healthshare_coverage", name: "Lost Health Share Coverage" },
  { id: "lost_outside_coverage", name: "Lost Outside Coverage" },
  {
    id: "lost_united_states_citizenship",
    name: "Lost United States Citizenship",
  },
  { id: "marriage", name: "Marriage" },
  { id: "member_aged_out", name: "Member Aged Out" },
  { id: "member_death", name: "Member Death" },
  { id: "moved_to_united_states", name: "Moved to the United States" },
  { id: "moved_out_of_united_states", name: "Moved Out of the United States" },
  { id: "newborn", name: "Newborn" },
  { id: "salary_reduction", name: "Salary Reduction" },
];

const removeKey = function removeKey(object, keyToRemove) {
  const { [keyToRemove]: _removedValue, ...result } = object;

  return result;
};

const getAvailableActionType = function getAvailableActionType(selectedAction) {
  switch (selectedAction) {
    case "employee_enroll":
      return "modify_coverage";
    case "employee_disenroll":
      return "remove_coverage";
    case "dependent_enroll":
      return "add_dependents";
    case "dependent_disenroll":
      return "remove_dependents";
    default:
      return null;
  }
};

const QleSubmissionFormComponent = function QleSubmissionFormComponent(props) {
  const memberRecord = useRecordContext(props);
  const memberId = memberRecord.id;
  const companyId = memberRecord.company_id;

  const [qleType, updateQleType] = useInput(undefined);
  const [confirmed, setConfirmed] = useState(false);

  const { data: availableActions, isLoading: actionsLoading } = useGetList(
    "qualifying_life_events/actions",
    { filter: { event_type: qleType } },
    { enabled: !!qleType }
  );
  const { data: enrollmentStates, isLoading: enrollmentsLoading } = useGetList(
    "enrollment_states",
    { filter: { member_id: memberId } }
  );
  const { data: dependentData, isLoading: dependentsLoading } = useGetList(
    "member_dependents",
    { filter: { member_id: memberId } }
  );
  const { data: offeringData, isLoading: offeringsLoading } = useGetList(
    "company_plan_offerings",
    { filter: { company_id: companyId } }
  );

  const currentEnrollmentData = formatCurrentEnrollmentData(
    enrollmentStates || []
  );
  const currentOfferingData = formatOfferingData(offeringData, dependentData);

  const qleSubmissionFormContextMemoized = useMemo(
    () => ({
      currentOfferingData,
      currentEnrollmentData,
    }),
    [currentOfferingData, currentEnrollmentData]
  );

  const offerings = currentOfferingData.allOfferings || {};

  const dataMutation = (data) => {
    const { selected_actions: selectedActions, ...result } = data;

    Object.keys(data.coverage_modifications).forEach((benefitType) => {
      if (selectedActions[benefitType] === "no_action") {
        result.coverage_modifications = removeKey(
          data.coverage_modifications,
          benefitType
        );
      } else {
        result.coverage_modifications[benefitType].available_action_type =
          getAvailableActionType(selectedActions[benefitType]);
      }
    });

    return result;
  };

  if (enrollmentsLoading || offeringsLoading || dependentsLoading)
    return <Loading />;

  const toolbar = (
    <Toolbar>
      <SaveButton type="button" disabled={!confirmed} />
    </Toolbar>
  );

  return (
    <div>
      <Create
        resource="qualifying_life_events"
        redirect={false}
        transform={dataMutation}
        record={{ event_data: { member_id: memberId } }}
      >
        <QleSubmissionFormContext.Provider
          value={qleSubmissionFormContextMemoized}
        >
          <SimpleForm toolbar={toolbar}>
            <SelectInput
              onChange={updateQleType}
              label="QLE Type"
              source="event_data.qualifying_life_event_type"
              validate={required()}
              choices={QLE_TYPES}
            />
            <DateInput
              label="QLE Date"
              source="event_data.event_date"
              validate={required()}
            />
            {availableActions &&
              Object.keys(offerings).map((benefitType) => (
                <SelectedActionComponent
                  key={benefitType}
                  availableActions={availableActions}
                  benefitType={benefitType}
                />
              ))}
            {actionsLoading && <Loading />}
            <div style={{ paddingBottom: "20px" }}>
              <label htmlFor="confirmation_checkbox" style={{ color: "red" }}>
                <input
                  type="checkbox"
                  id="confirmation_checkbox"
                  checked={confirmed}
                  onChange={() => setConfirmed(!confirmed)}
                  required
                />
                I understand I am creating a QLE with the above details and this
                cannot be undone.
              </label>
            </div>
          </SimpleForm>
        </QleSubmissionFormContext.Provider>
      </Create>
    </div>
  );
};

const QleSubmissionForm = function QleSubmissionForm() {
  return <QleSubmissionFormComponent key="QleSubmissionFormComponent" />;
};

export default QleSubmissionForm;
