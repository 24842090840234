import { React } from "react";

import { useRecordContext, Show, TextInput } from "react-admin";
import { DocumentUploadButton } from "../../DocumentTab/DocumentUploadButton";

const PriceGridUploadComponent = function PriceGridUploadComponent() {
  const companyBenefitOfferingId = useRecordContext().id;
  const uploadRoute = "price_grids";

  return (
    <Show title=" > Upload">
      <DocumentUploadButton
        uploadRoute={uploadRoute}
        resourceId={companyBenefitOfferingId}
      >
        <TextInput label="Display Name" source="display_name" />
      </DocumentUploadButton>
    </Show>
  );
};

const PriceGridUploadTab = function PriceGridUploadTab() {
  return <PriceGridUploadComponent key="PriceGridUploadComponent" />;
};

export default PriceGridUploadTab;
