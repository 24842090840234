import { React } from "react";
import { SimpleShowLayout } from "react-admin";
import { Attribute } from "../../../GenericAttributes";

const PlanMetadataShowAttributes = function (props) {
  const { attributes } = props;
  const showStyle = { padding: "0" };

  return (
    <SimpleShowLayout sx={showStyle}>
      {attributes.map((attribute) => Attribute("Show", attribute))}
    </SimpleShowLayout>
  );
};

export default PlanMetadataShowAttributes;
