import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, useRecordContext } from "react-admin";

const TruncatedFieldComponent = function TruncatedFieldComponent({ source }) {
  const record = useRecordContext();
  const [truncated, updateTruncated] = useState(true);
  const max = 100;
  const fullText = record[source] || "";
  const truncatedText =
    fullText.length <= max
      ? record[source]
      : fullText.substring(0, max + 1) + "...";
  const showButton = fullText.length > max;

  return (
    <div>
      {truncated ? truncatedText : fullText}
      {showButton ? (
        <Button
          onClick={() => updateTruncated(!truncated)}
          label={truncated ? "Show more" : "Show less"}
        />
      ) : null}
    </div>
  );
};

TruncatedFieldComponent.propTypes = {
  source: PropTypes.string.isRequired,
};

export default TruncatedFieldComponent;
