import React, { useState, useEffect } from "react";

import {
  SaveButton,
  CreateBase,
  SimpleForm,
  SelectInput,
  useRefresh,
  SimpleShowLayout,
  LinearProgress,
} from "react-admin";

import { Grid, Typography } from "@mui/material";

import { WorkflowActionButton } from "../../../WorkflowActionButton";

import { useTakeAction } from "lib/Hooks";

const formatNoyoPerson = (data) => {
  if (!data) return {};
  const { id, person, relationship } = data;
  if (!person) return {};
  const {
    first_name,
    last_name,
    sex,
    date_of_birth,
    home_address: { street_one },
  } = person;
  return {
    id,
    first_name,
    last_name,
    sex,
    date_of_birth,
    home_address: street_one,
    relationship,
  };
};

const formatNoyoPlan = ({ plan }) => {
  if (!plan) return {};
  const { name: planName, code: planCode, ...rest } = plan;

  return {
    plan_name: planName,
    plan_code: planCode,
    ...rest,
  };
};

const noyoResourceNames = {
  members: "Employee",
  dependents: "Dependent",
  company_plan_offerings: "Group Plan",
};

const noyoResourceFormatters = {
  members: formatNoyoPerson,
  dependents: formatNoyoPerson,
  company_plan_offerings: formatNoyoPlan,
};

const NoyoObjectMapper = function NoyoObjectMapper({
  sageObject,
  noyoChoices,
  resource,
  children,
}) {
  const defaultValues = {
    internal_id: sageObject.id,
    external_id_owner: "noyo",
    internal_resource: resource,
  };
  const [noyoData, setNoyoData] = useState();
  const [currentId, setCurrentId] = useState(null);
  const refresh = useRefresh();

  const [takeAction, { isLoading: noyoDataLoading }] = useTakeAction(
    "external_id_mappings",
    { action: "validate" },
    {
      onSuccess: (response) => setNoyoData(response.data),
      onError: (response) => setNoyoData(response.body.error),
      onSettled: () => refresh(),
    }
  );

  const noyoDataDisplay = () => {
    if (noyoDataLoading) return {};
    if (noyoData) return noyoResourceFormatters[resource](noyoData);
    return `Select a noyo ${noyoResourceNames[resource]}.`;
  };

  const validate = (id) => {
    if (!id) {
      setNoyoData(undefined);
      return;
    }
    takeAction({
      id,
      options: {
        body: JSON.stringify({ ...defaultValues, external_id: id }),
      },
    });
  };

  useEffect(() => {
    if (!currentId) return;
    validate(currentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);

  return (
    <CreateBase
      resource="external_id_mappings"
      redirect={false}
      actions={null}
      mutationOptions={{ onSuccess: refresh }}
    >
      <Grid container direction="row">
        <Grid item sx={{ marginLeft: "20px" }}>
          <SimpleForm
            defaultValues={defaultValues}
            toolbar={false}
            sx={{ padding: 0 }}
          >
            <Grid container direction="column">
              <Grid item>
                <SelectInput
                  source="external_id"
                  helperText={false}
                  label={`Noyo ${noyoResourceNames[resource]} to Map to`}
                  choices={noyoChoices}
                  onChange={(evt) => setCurrentId(evt.target.value)}
                />
                <SaveButton
                  label="Map"
                  sx={{ marginLeft: "15px", marginTop: "8px" }}
                />
              </Grid>
              {["members", "dependents"].includes(resource) && (
                <Grid item>
                  <WorkflowActionButton
                    id={sageObject.id}
                    action="create_in_noyo"
                    successMsg={() =>
                      `${noyoResourceNames[resource]} created in Noyo`
                    }
                    resource={resource}
                    label="Create In Noyo"
                    sx={{ paddingTop: "8px" }}
                  />
                </Grid>
              )}
            </Grid>
          </SimpleForm>
        </Grid>

        <Grid item sx={{ marginLeft: "20px" }}>
          <Typography variant="body2">
            Noyo {noyoResourceNames[resource]} to map to:
          </Typography>
          {!noyoData && !noyoDataLoading && (
            <Typography variant="body1">
              No noyo {noyoResourceNames[resource]} selected.
            </Typography>
          )}
          {noyoDataLoading && <LinearProgress />}
          {!noyoDataLoading && noyoData && (
            <SimpleShowLayout record={noyoDataDisplay()}>
              {children}
            </SimpleShowLayout>
          )}
        </Grid>
      </Grid>
    </CreateBase>
  );
};

export default NoyoObjectMapper;
