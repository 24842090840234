import { React, useState } from "react";

import {
  List,
  Datagrid,
  Form,
  SelectInput,
  SaveButton,
  useRefresh,
  useNotify,
  useUpdate,
  useCreate,
  useGetList,
  useGetOne,
  useRecordContext,
  Loading,
  Error,
  RecordContextProvider,
  SaveContextProvider,
  useCreateSuggestionContext,
} from "react-admin";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";

import { useGetHistory } from "../../../../../../lib/Hooks";

import FullNameLink from "./FullNameLink";

const CompanyInsuranceClassCreate = function CompanyInsuranceClassCreate({
  companyId,
}) {
  const { onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const refresh = useRefresh();

  const [nameValue, setNameValue] = useState("");
  const [waitingPeriodValue, setWaitingPeriodValue] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    create(
      "insurance_classes",
      {
        data: {
          name: nameValue,
          company_id: companyId,
          waiting_period: waitingPeriodValue,
        },
      },
      {
        onSuccess: (data) => {
          setNameValue("");
          setWaitingPeriodValue(0);
          onCreate(data);
          refresh();
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New insurance class"
            value={nameValue}
            onChange={(event) => setNameValue(event.target.value)}
            autoFocus
          />
          <TextField
            inputProps={{ type: "number" }}
            label="Waiting period"
            value={waitingPeriodValue}
            onChange={(event) => {
              setWaitingPeriodValue(Number(event.target.value));
            }}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const InsuranceClassEdit = function InsuranceClassEdit({ offeringStartDate }) {
  const notify = useNotify();
  const refresh = useRefresh();
  const member = useRecordContext();

  const { id, company_id: companyId } = member;

  const {
    data: companyInsuranceClasses,
    isLoading: choicesLoading,
    isError: choicesError,
  } = useGetList(
    "insurance_classes",
    { filter: { company_id: companyId } },
    { enabled: !!companyId }
  );

  const {
    data: memberInsuranceClasses,
    isLoading: historyLoading,
    isError: historyLoadError,
  } = useGetHistory("member_insurance_classes", { filter: { member_id: id } });

  const [update, { isLoading: updateLoading }] = useUpdate();
  const [create, { isLoading: createLoading }] = useCreate();

  if (choicesLoading || historyLoading)
    return <CircularProgress sx={{ height: "15px", fontSize: "10px" }} />;
  if (historyLoadError || choicesError)
    return <Error sx={{ height: "15px" }} />;

  const currentRecord =
    memberInsuranceClasses[memberInsuranceClasses.length - 1] || {};
  const saveFunction = currentRecord && currentRecord.id ? update : create;

  const baseRecord = {
    member_id: id,
    start_date: offeringStartDate,
  };

  const save = (formValues) => {
    saveFunction(
      "member_insurance_classes",
      {
        id: currentRecord.id,
        data: { ...currentRecord, ...formValues, ...baseRecord },
      },
      {
        onSuccess: () => {
          refresh();
          notify("Insurance class assigned", { type: "success" });
        },
        onError: (error) => {
          refresh();
          notify(error.message, { type: "error" });
        },
      }
    );
  };

  return (
    <RecordContextProvider value={currentRecord}>
      <SaveContextProvider
        value={{
          save,
          saving: updateLoading || createLoading,
          mutationMode: "pessimistic",
        }}
      >
        <Form>
          <Grid container>
            <Grid item sx={{ marginRight: "10px" }}>
              <SelectInput
                label={"Insurance Class"}
                source={"insurance_class_id"}
                choices={companyInsuranceClasses}
                helperText={false}
                optionText="name"
                create={<CompanyInsuranceClassCreate companyId={companyId} />}
              />
            </Grid>
            <Grid item sx={{ marginTop: "19px" }}>
              <SaveButton />
            </Grid>
          </Grid>
        </Form>
      </SaveContextProvider>
    </RecordContextProvider>
  );
};

const AssignInsuranceClassesPrompt = function AssignInsuranceClassesPrompt({
  data,
}) {
  const {
    company_id: companyId,
    company_benefit_selection_window_id: selectionWindowId,
  } = data;

  const { data: selectionWindow, isLoading } = useGetOne(
    "company_benefit_selection_windows",
    { id: selectionWindowId }
  );

  if (isLoading) return <Loading sx={{ height: "15px" }} />;

  const { offering_start_date: offeringStartDate } = selectionWindow;

  return (
    <Box>
      <Typography>
        Assign an insurance class to each benefits eligible member:
      </Typography>
      <List
        resource="members"
        filter={{
          company_id: companyId,
        }}
        actions={false}
        perPage={5}
        disableSyncWithLocation={true}
        empty={
          <Typography variant="h5">No members for this company.</Typography>
        }
      >
        <Datagrid bulkActionButtons={false}>
          <FullNameLink source="id" reference="members" label="Member" />
          <InsuranceClassEdit offeringStartDate={offeringStartDate} />
        </Datagrid>
      </List>
    </Box>
  );
};

export default AssignInsuranceClassesPrompt;
