/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import { React, useMemo } from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";
import ParentRecordContext from "./ParentRecordContext";

const ParentRecordContextProvider = function ParentRecordContextProvider({
  resourceIdKey,
  children,
}) {
  const record = useRecordContext();
  const contextValue = useMemo(() => {
    if (!record) return {};

    const { id, ...otherFields } = record;
    return {
      [resourceIdKey]: id,
      ...otherFields,
    };
  }, [record, resourceIdKey]);

  return (
    <ParentRecordContext.Provider value={contextValue}>
      {children}
    </ParentRecordContext.Provider>
  );
};

ParentRecordContextProvider.propTypes = {
  resourceIdKey: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ParentRecordContextProvider.defaultProps = {
  resourceIdKey: "id",
};

export default ParentRecordContextProvider;
