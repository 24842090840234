import React from "react";

import { TextField, ResourceContextProvider } from "react-admin";

import { MatchResolver, PlanOfferingCreator } from "./components";

import { explainerStyle } from "./styles";

const ResolveNoyoGroupPlanMismatches = function ResolveNoyoGroupPlanMismatches({
  data,
}) {
  const {
    unmapped_plan_offerings: unmappedSageObjects,
    unmapped_group_plans: unmappedNoyoObjects,
    offered_provider_ids: offeredProviderIds,
    company_id: companyId,
    group_enrollment_to_plan_year_id_map: groupEnrollmentToPlanYearIdMap,
  } = data;

  const noyoChoices = unmappedNoyoObjects.map(
    ({ name: planName, plan_code: planCode, ...rest }) => ({
      plan_name: planName,
      plan_code: planCode,
      name: `${planCode} - ${planName}`,
      ...rest,
    })
  );

  return (
    <div>
      {unmappedNoyoObjects[0] && unmappedSageObjects[0] && (
        <div style={explainerStyle}>
          Sage Plan Offerings and Noyo Group Plans exist but could not be
          automatically matched. Assign each Plan Offering below to its
          corresponding Noyo object.
        </div>
      )}
      <ResourceContextProvider value="company_plan_offerings">
        {unmappedNoyoObjects[0] &&
          unmappedSageObjects.map((sageObject) => (
            <MatchResolver
              key={sageObject.id}
              sageObject={sageObject}
              noyoChoices={noyoChoices}
              createButton={false}
            >
              <TextField source="plan_name" />
              <TextField source="plan_code" />
            </MatchResolver>
          ))}
      </ResourceContextProvider>

      {!unmappedSageObjects[0] && unmappedNoyoObjects[0] && (
        <div style={explainerStyle}>
          The following Group Plans exist in Noyo for which we have no matching
          plan offering. Plan offerings need to be created to match all Noyo
          plans.
        </div>
      )}
      {!unmappedSageObjects[0] &&
        unmappedNoyoObjects.map((noyoObject) => (
          <PlanOfferingCreator
            key={noyoObject.id}
            groupPlan={noyoObject}
            groupEnrollmentToPlanYearIdMap={groupEnrollmentToPlanYearIdMap}
            offeredProviderIds={offeredProviderIds}
            companyId={companyId}
          />
        ))}
    </div>
  );
};

export default ResolveNoyoGroupPlanMismatches;
