import { React, useState } from "react";
import { useRecordContext } from "react-admin";
import { ActionButton } from "./ActionButton";
import { Typography } from "@mui/material";

const GenerateApprovalUrlButtonComponent = function GenerateApprovalUrlButton(
  props
) {
  const [url, setUrl] = useState("");
  const companyBenefitOfferingId = useRecordContext(props).id;

  function displayUrlOrError(result) {
    if (result?.data?.url) {
      setUrl(result.data.url);
    }
  }

  return (
    <div>
      <ActionButton
        resource="company_benefit_offerings"
        action="approval_url"
        id={companyBenefitOfferingId}
        label="Generate Approval URL"
        callback={displayUrlOrError}
        method="GET"
      />
      {!!url.length && (
        <div>
          <a href={url} target="_blank" rel="noreferrer">
            <Typography>Right click to copy link address</Typography>
          </a>
        </div>
      )}
    </div>
  );
};

const GenerateApprovalUrlButton = function GenerateApprovalUrlButton() {
  return (
    <GenerateApprovalUrlButtonComponent key="GenerateApprovalUrlButtonComponent" />
  );
};

export default GenerateApprovalUrlButton;
