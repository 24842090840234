import { React, useState, useEffect, useCallback } from "react";
import { useRecordContext } from "react-admin";
import EntityTable from "../InternationalCompanyEnrollments/EntityTable";
import EntityForm from "../InternationalCompanyEnrollments/EntityForm";
import Dropdown from "../InternationalCompanyEnrollments/Dropdown";
import { Box } from "@mui/material";
import { BASE_URL } from "lib/constants";
import { tableForSchema } from "../InternationalCompanyEnrollments/schemaHelpers";
import HttpClient from "lib/HttpClient";

const InternationalCompanyOfferingsTabComponent = (props) => {
  const canonicalCompanyId = useRecordContext(props).cdms_canonical_id;
  const [state, setState] = useState({
    error: null,
    validBenefitTypes: ["health"],
    selectedBenefitType: "",
    companyOfferingsSchema: null,
    companyOfferings: null,
  });
  const [table, setTable] = useState([]);
  const schemaUrl = `${BASE_URL}/international/${state.selectedBenefitType}/company_offerings/schema`;
  const companyOfferingsUrl = `${BASE_URL}/international/${state.selectedBenefitType}/company_offerings?company_id=${canonicalCompanyId}`;

  const updateState = useCallback(
    (key, result) => {
      setState((prevState) => ({ ...prevState, [key]: result }));
    },
    [setState]
  );

  const setSelectedBenefitType = (event) => {
    updateState("selectedBenefitType", event.target.value);
  };

  const fetchDataCallback = useCallback(
    async (url, key) => {
      const { json } = await HttpClient(url);
      updateState(key, json);
    },
    [updateState]
  );

  function handleSubmit() {
    fetchDataCallback(companyOfferingsUrl, "companyOfferings");
  }

  // Fetch schema and company offerings
  useEffect(() => {
    if (state.selectedBenefitType) {
      fetchDataCallback(schemaUrl, "companyOfferingsSchema");
      fetchDataCallback(companyOfferingsUrl, "companyOfferings");
    }
  }, [
    fetchDataCallback,
    schemaUrl,
    companyOfferingsUrl,
    state.selectedBenefitType,
  ]);

  // Fetch table
  useEffect(() => {
    const propertiesToIgnoreInList = [
      "plans_id",
      "company_id",
      "external_ids",
      "created_at",
      "updated_at",
    ];
    function getTable() {
      if (state.companyOfferingsSchema && state.companyOfferings) {
        setTable(
          tableForSchema(
            state.companyOfferingsSchema,
            state.companyOfferings,
            propertiesToIgnoreInList
          )
        );
      }
    }
    getTable();
  }, [state.companyOfferings, state.companyOfferingsSchema]);

  const propertiesToIgnoreInCreate = [
    "id",
    "external_ids",
    "created_at",
    "updated_at",
  ];

  return (
    <Box sx={{ margin: [2, 0, 0, 2] }}>
      <Dropdown
        label="Benefit Type"
        selected={state.selectedBenefitType}
        setSelected={setSelectedBenefitType}
        options={state.validBenefitTypes}
        sx={{ width: 200 }}
      />
      {state.companyOfferingsSchema ? (
        <EntityForm
          presetFormValues={{ company_id: canonicalCompanyId }}
          schema={state.companyOfferingsSchema || {}}
          propertiesToIgnore={propertiesToIgnoreInCreate}
          baseApiUrl={BASE_URL + "/international"}
          createUrl={companyOfferingsUrl}
          onSuccess={handleSubmit}
          sx={{ width: 300 }}
        />
      ) : (
        <></>
      )}
      {table.length > 0 ? <EntityTable table={table} /> : <></>}
    </Box>
  );
};

const InternationalCompanyOfferingsTab = () => {
  return <InternationalCompanyOfferingsTabComponent />;
};

export default InternationalCompanyOfferingsTab;
