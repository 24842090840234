import { React, useContext } from "react";
import PropTypes from "prop-types";
import { CheckboxGroupInput, DateInput, required } from "react-admin";

import { QleSubmissionFormContext } from "../../context";

const DependentEnrollComponent = function DependenteEnrollComponent(props) {
  const { benefitType, enrolledDependentIds } = props;
  const { currentOfferingData } = useContext(QleSubmissionFormContext);

  const { allDependents } = currentOfferingData;

  const nonEnrolledDependents = Object.values(allDependents).filter(
    (dependent) => !enrolledDependentIds.includes(dependent.id)
  );

  const choices = nonEnrolledDependents.map((dependent) => ({
    id: dependent.id,
    name: `${dependent.first_name} ${dependent.last_name}`,
  }));

  return (
    <div>
      <h4 style={{ textTransform: "capitalize" }}>{benefitType}</h4>
      <DateInput
        label="Coverage Start Date"
        source={`coverage_modifications.${benefitType}.action_details.coverage_start_date`}
        validate={required()}
      />
      <br />
      <CheckboxGroupInput
        label={`Dependents to add to ${benefitType} policy:`}
        source={`coverage_modifications.${benefitType}.action_details.added_dependent_ids`}
        choices={choices}
        validate={required()}
      />
    </div>
  );
};
DependentEnrollComponent.propTypes = {
  benefitType: PropTypes.string.isRequired,
  enrolledDependentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DependentEnrollComponent;
