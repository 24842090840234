import { useRecordContext, useSidebarState } from "react-admin";
/* eslint-disable camelcase */

const ShowTitle = ({ config }) => {
  const [isSidebarOpen] = useSidebarState();
  const { title, tab_name, title_identifiers } = config;

  const record = useRecordContext();
  let currentTitle;
  if (title && !record) {
    // If no record exists then there's no path to render to users.
    // In this case we only want to show a title if the sidebar is closed
    if (isSidebarOpen) {
      return null;
    }

    currentTitle = title;
  } else if (record && !tab_name && title) {
    const recordIdentifier = title_identifiers
      ? title_identifiers.reduce((prev, curr) => `${prev} ${record[curr]}`, "")
      : record.name;
    if (!recordIdentifier) currentTitle = title;
    else currentTitle = `${title} > ${recordIdentifier}`;
  } else currentTitle = `\xA0> ${tab_name}`;

  return currentTitle;
};

export default ShowTitle;
