import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import {
  dentalAttributes,
  medicalAttributes,
  visionAttributes,
} from "./planMetadataAttributes";
import PlanMetadataCreateAttributes from "./components/PlanMetadataCreateAttributes";

const PlanMetadata = function PlanMetadata(props) {
  const { selectedBenefitType } = props;

  if (selectedBenefitType === "medical") {
    return (
      <PlanMetadataCreateAttributes
        key="MedicalPlanMetadataAttributes"
        attributes={medicalAttributes}
      />
    );
  } else if (selectedBenefitType === "dental") {
    return (
      <PlanMetadataCreateAttributes
        key="DentalPlanMetadataAttributes"
        attributes={dentalAttributes}
      />
    );
  } else if (selectedBenefitType === "vision") {
    return (
      <PlanMetadataCreateAttributes
        key="VisionPlanMetadataAttributes"
        attributes={visionAttributes}
      />
    );
  } else {
    return null;
  }
};

const PlanMetadataCreateComponent = function PlanMetadataCreateComponent() {
  const { reset, watch } = useFormContext();
  const selectedBenefitType = watch("benefit_type");

  useEffect(() => {
    reset({
      benefit_type: selectedBenefitType,
    });
  }, [selectedBenefitType, reset]);

  return (
    <Box>
      <hr></hr>
      <br></br>
      <PlanMetadata selectedBenefitType={selectedBenefitType} />
    </Box>
  );
};

const PlanMetadataCreate = function PlanMetadataCreate() {
  return <PlanMetadataCreateComponent key="PlanMetadataCreateComponent" />;
};

export default PlanMetadataCreate;
