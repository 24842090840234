import { React } from "react";
import { Button, useNotify } from "react-admin";
import PropTypes from "prop-types";

const DownloadButton = function DownloadButton({
  url,
  fileName,
  label,
  disabled = false,
}) {
  const notify = useNotify();

  const download = async () => {
    const { token } = JSON.parse(localStorage.getItem("auth"));

    const requestOptions = {
      headers: new Headers({ Authorization: token }),
    };

    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const file = await response.blob();
        const objectUrl = document.createElement("a");

        objectUrl.href = URL.createObjectURL(file);
        objectUrl.setAttribute("download", fileName);
        objectUrl.click();
      } else {
        const data = await response.json();
        notify(data?.message, { type: "error" });
      }
    } catch (error) {
      notify(error, { type: "error" });
    }
  };

  return <Button disabled={disabled} onClick={download} label={label} />;
};

DownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default DownloadButton;
