/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import { useRecordContext } from "react-admin";

const QleSelectedActionsComponent = function QleSelectedActionsComponent(
  props
) {
  const qle = useRecordContext(props);

  return (
    <div>
      <pre>{JSON.stringify(qle.selected_actions, null, "\t")}</pre>
    </div>
  );
};

const QleSelectedActionsDisplay = function QleSelectedActionsDisplay() {
  return (
    <QleSelectedActionsComponent
      label="Selected Actions"
      key="QleSelectedActionsComponent"
    />
  );
};

export default QleSelectedActionsDisplay;
