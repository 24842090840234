import { React, useState, useEffect, useCallback } from "react";
import { useRecordContext } from "react-admin";
import EntityTable from "../InternationalCompanyEnrollments/EntityTable";
import EntityForm from "../InternationalCompanyEnrollments/EntityForm";
import Dropdown from "../InternationalCompanyEnrollments/Dropdown";
import { Box } from "@mui/material";
import { tableForSchema } from "../InternationalCompanyEnrollments/schemaHelpers";
import { BASE_URL } from "lib/constants";
import HttpClient from "lib/HttpClient";

const LifeAndDisabilityCompanyEnrollmentsComponent = (props) => {
  const companyId = useRecordContext(props).id;
  const [table, setTable] = useState([]);
  const [state, setState] = useState({
    error: null,
    selectedBenefitType: "",
    companyEnrollmentsSchema: null,
    companyEnrollments: null,
  });
  const validBenefitTypes = [
    "life_insurance",
    "short_term_disability",
    "long_term_disability",
  ];

  const updateState = useCallback(
    (key, result) => {
      setState((prevState) => ({ ...prevState, [key]: result }));
    },
    [setState]
  );

  const fetchDataCallback = useCallback(
    async (url, key) => {
      const { json } = await HttpClient(url);
      updateState(key, json);
    },
    [updateState]
  );

  const resolveSchemaUrl = (benefitType) => {
    switch (benefitType) {
      case "life_insurance": {
        return `${BASE_URL}/${benefitType}/company_enrollments/schema`;
      }
      case "short_term_disability":
      case "long_term_disability": {
        return `${BASE_URL}/disability/company_enrollments/${benefitType}/schema`;
      }
      default: {
        return null;
      }
    }
  };

  const resolveEnrollmentsUrl = useCallback(
    (benefitType) => {
      switch (benefitType) {
        case "life_insurance": {
          return `${BASE_URL}/${benefitType}/company_enrollments/${companyId}/enrollments`;
        }
        case "short_term_disability":
        case "long_term_disability": {
          return `${BASE_URL}/disability/company_enrollments/${companyId}/${benefitType}/enrollments`;
        }
        default: {
          return null;
        }
      }
    },
    [companyId]
  );

  const setSelectedBenefitType = (event) => {
    updateState("selectedBenefitType", event.target.value);
  };

  // Fetch schema
  useEffect(() => {
    if (state.selectedBenefitType) {
      fetchDataCallback(
        resolveSchemaUrl(state.selectedBenefitType),
        "companyEnrollmentsSchema"
      );
    }
  }, [state.selectedBenefitType, fetchDataCallback]);

  // Fetch company enrollments
  useEffect(() => {
    if (state.selectedBenefitType) {
      fetchDataCallback(
        resolveEnrollmentsUrl(state.selectedBenefitType),
        "companyEnrollments"
      );
    }
  }, [state.selectedBenefitType, fetchDataCallback, resolveEnrollmentsUrl]);

  // Fetch table
  useEffect(() => {
    const propertiesToIgnoreInList = [
      "company_id",
      "external_ids",
      "created_at",
      "updated_at",
    ];
    async function getTable() {
      if (state.companyEnrollmentsSchema && state.companyEnrollments) {
        setTable(
          tableForSchema(
            state.companyEnrollmentsSchema,
            state.companyEnrollments,
            propertiesToIgnoreInList
          )
        );
      }
    }
    getTable();
  }, [state.companyEnrollments, state.companyEnrollmentsSchema]);

  function onSuccess() {
    fetchDataCallback(
      resolveEnrollmentsUrl(state.selectedBenefitType),
      "companyEnrollments"
    );
  }

  const propertiesToIgnoreInCreate = [
    "id",
    "company_noncontributory_offering_id",
    "company_noncontributory_std_offering_id",
    "company_noncontributory_ltd_offering_id",
    "external_ids",
    "created_at",
    "updated_at",
  ];

  return (
    <Box sx={{ margin: [2, 0, 0, 2] }}>
      <Dropdown
        label="Benefit Type"
        selected={state.selectedBenefitType}
        setSelected={setSelectedBenefitType}
        options={validBenefitTypes}
        sx={{ width: 200 }}
      />
      {table.length > 0 ? <EntityTable table={table} /> : <></>}
      {state.companyEnrollmentsSchema ? (
        <EntityForm
          presetFormValues={{ company_id: companyId }}
          schema={state.companyEnrollmentsSchema || {}}
          propertiesToIgnore={propertiesToIgnoreInCreate}
          createUrl={resolveEnrollmentsUrl(state.selectedBenefitType)}
          onSuccess={onSuccess}
          sx={{ width: 300 }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

const LifeAndDisabilityCompanyEnrollmentsTab = () => {
  return <LifeAndDisabilityCompanyEnrollmentsComponent />;
};

export default LifeAndDisabilityCompanyEnrollmentsTab;
