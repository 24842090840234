/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import { useRecordContext, useGetOne } from "react-admin";

function PriceComparisonTabComponent(props) {
  const censusId = useRecordContext(props).id;
  const url = `company_census_price_comparison`;
  const { data, isLoading, error } = useGetOne(url, { id: censusId });
  if (isLoading) {
    return <p>Loading</p>;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <div>
      <h3>Current Large Group Premium</h3>
      <p>{data.current_large_group_premium}</p>
      <h3>Quoted Large Group Premium</h3>
      <p>{data.quoted_large_group_premium}</p>
      {data.quoted_small_group_premiums.map((quoted_premium, index) => {
        let percentage_difference_string = "None";
        if (quoted_premium.percentage_difference) {
          percentage_difference_string = `${quoted_premium.percentage_difference}% difference`;
        }
        return (
          <div key={index}>
            <h3>Quoted Premium {index + 1}</h3>
            <p>{quoted_premium.quoted_premium}</p>
            <p>{percentage_difference_string}</p>
          </div>
        );
      })}
    </div>
  );
}

const PriceComparisonTab = function PriceComparisonTab() {
  return <PriceComparisonTabComponent />;
};

export default PriceComparisonTab;
