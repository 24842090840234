import currency from "currency.js";

const Formatters = {
  SSN: {
    format: function format(value) {
      if (!value) return "";

      let formatttedValue;
      formatttedValue = value.replace(/[^\d*]/g, "");
      formatttedValue = formatttedValue.replace(/^([\d*]{3})/, "$1-");
      formatttedValue = formatttedValue.replace(/-([\d*]{2})/, "-$1-");
      formatttedValue = formatttedValue.replace(/([\d*])-(\d{4}).*/, "$1-$2");
      return formatttedValue.slice(0, 11);
    },
    parse: function parse(value) {
      return value.replace(/-/g, "").slice(0, 9);
    },
  },
  phoneNumber: {
    format: function format(value) {
      if (!value) return "";

      let formatttedValue;
      formatttedValue = value.replace(/[^\d*]/g, "");
      formatttedValue = formatttedValue.replace(/^([\d*]{0})/, "$1( ");
      formatttedValue = formatttedValue.replace(/([\d*]{3})/, "$1 ) ");
      formatttedValue = formatttedValue.replace(/([\d* () -]{11})/, "$1 - ");
      return formatttedValue.slice(0, 20);
    },
    parse: function parse(value) {
      return value
        .replace(/-/g, "")
        .replace(/\(/, "")
        .replace(/\)/, "")
        .replace(/\s/g, "")
        .slice(0, 10);
    },
  },
  currency: {
    format: function format(value) {
      if (!value && value !== 0) return "";

      return value / 100;
    },
    parse: function parse(value) {
      return currency(value).intValue;
    },
  },
  percent: {
    format: function format(value) {
      if (!value && value !== 0) return "";

      return value;
    },
    parse: function parse(value) {
      return value * 1;
    },
  },
  hours: {
    format: function format(minutes) {
      if (!minutes && minutes !== 0) return "";

      return minutes / 60;
    },
    parse: function parse(hours) {
      return hours * 60;
    },
  },
};

export default Formatters;
