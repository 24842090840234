import * as React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  ReferenceArrayInput,
  ReferenceField,
  CheckboxGroupInput,
  useRecordContext,
  RecordRepresentation,
} from "react-admin";
import createValidationFunctionArray from "../../../../lib/Validations";
import { useParentRecordContext } from "../../contexts";

const generateOptionText = (references, source) => {
  if (references.length === 0) {
    if (!!source) {
      return <TextField source={source} />;
    }

    return <RecordRepresentation />;
  }
  const [nextReference, ...remainingReferences] = references;
  const [referenceResource, referenceField] = nextReference;

  return (
    <ReferenceField
      link={false}
      source={referenceField}
      reference={referenceResource}
    >
      {generateOptionText(remainingReferences, source)}
    </ReferenceField>
  );
};

const MultiCheckboxReferenceComponent =
  function MultiCheckboxReferenceComponent(props) {
    const { attribute, references } = props;
    const { label, source, validations } = attribute;
    const filterParams = attribute.filter_params;
    const [sortField = "created_at", sortOrder = "DESC"] =
      attribute.sort_params || [];
    const recordValues = useRecordContext() || {};
    const [nextReference, ...remainingReferences] = references;
    const [referenceResource, referenceField] = nextReference;
    const parentRecordContext = useParentRecordContext();
    let filter;

    if (filterParams) {
      /* eslint-disable no-param-reassign */
      filter = filterParams.reduce((result, paramPairs) => {
        const [filterKey, recordKey] = paramPairs;
        result[filterKey] =
          recordValues[recordKey] || parentRecordContext[recordKey];
        return result;
      }, {});
      /* eslint-enable no-param-reassign */
    }
    return (
      <div>
        <ReferenceArrayInput
          source={referenceField}
          filter={filter}
          validate={createValidationFunctionArray(validations)}
          reference={referenceResource}
          perpage={10000}
          sort={{ field: sortField, order: sortOrder }}
        >
          <CheckboxGroupInput
            label={label}
            optionText={generateOptionText(remainingReferences, source)}
          />
        </ReferenceArrayInput>
      </div>
    );
  };

MultiCheckboxReferenceComponent.propTypes = {
  attribute: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ).isRequired,
  references: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default MultiCheckboxReferenceComponent;
