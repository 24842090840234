import React, { useState } from "react";
import { useRecordContext, Button } from "react-admin";
import { ActionButton } from "../ActionButton";

const ViewAsButtonComponent = () => {
  const [toggleOpenButton, setToggleOpenButton] = useState(false);
  const memberId = useRecordContext().id;
  const afterClicked = () => setToggleOpenButton(true);
  const openAdminInNewTab = () => {
    const replacedUrl = window.location.href.replace("tools.", "");

    setToggleOpenButton(false);
    window.open(replacedUrl, "_blank");
  };

  if (toggleOpenButton) {
    return <Button label="Open As" onClick={openAdminInNewTab} />;
  }

  return (
    <ActionButton
      resource="view_as_member"
      id={memberId}
      label="View As"
      callback={afterClicked}
    />
  );
};

const ViewAsButton = function ViewAsButton() {
  return <ViewAsButtonComponent key="ViewAsButtonComponent" />;
};

export default ViewAsButton;
