/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Datagrid, List, ShowButton } from "react-admin";
import { Attribute } from "../GenericAttributes";
import ShowTitle from "./ShowTitle";

const GenericList = function GenericList(config) {
  return function ListComponent(props) {
    const { attributes, filters = [] } = config;
    const filterConfig = attributes.filter(
      (attribute) =>
        filters.includes(attribute.source) || filters.includes(attribute.label)
    );
    let filterComponents = filterConfig.map((attribute) =>
      Attribute("Edit", attribute)
    );

    return (
      <List
        {...props}
        sort={{ field: "created_at", order: "ASC" }}
        perPage={25}
        title={<ShowTitle config={config} />}
        filters={filterComponents}
        exporter={config.exporter}
      >
        <Datagrid>
          {attributes.map((attribute) => Attribute("List", attribute))}
          <ShowButton />
        </Datagrid>
      </List>
    );
  };
};

export default GenericList;
