import { React } from "react";
import { Create, SimpleForm } from "react-admin";
import { Inputs } from "./components/Inputs";

const DocumentRequirementCreate = function DocumentRequirementCreate() {
  return (
    <Create redirect="list">
      <SimpleForm>{Inputs.map((field) => field)}</SimpleForm>
    </Create>
  );
};

export default DocumentRequirementCreate;
