import LifeAndDisabilityCompanyEnrollmentsTab from "./LifeAndDisabilityCompanyEnrollmentsTab";

export default {
  sub_resource_config: {
    tab_name: "Life and Disability Enrollments",
    view_component: LifeAndDisabilityCompanyEnrollmentsTab(),
    components: ["CustomView"],
  },
  top_level_resource_config: {},
};
