import * as React from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../ProcessManager";

const CompanyBenefitSelectionWindowProcessingComponent =
  function CompanyBenefitSelectionWindowProcessingComponent(props) {
    const companySelectionWindowId = useRecordContext(props).id;

    return (
      <ProcessManager
        processName="company_benefit_selection_window_processing"
        ownerId={companySelectionWindowId}
        kickoffButtonLabel="Start Process"
      />
    );
  };

const CompanyBenefitSelectionWindowProcessingManager =
  function CompanyBenefitSelectionWindowProcessingManager() {
    return (
      <CompanyBenefitSelectionWindowProcessingComponent
        label="Selection Window Processing"
        key="CompanyBenefitSelectionWindowProcessingComponent"
      />
    );
  };

export default CompanyBenefitSelectionWindowProcessingManager;
