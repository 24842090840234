import InternationalMemberEnrollmentsTab from "./InternationalMemberEnrollmentsTab";

export default {
  sub_resource_config: {
    tab_name: "International Enrollments",
    view_component: InternationalMemberEnrollmentsTab(),
    components: ["CustomView"],
  },
  top_level_resource_config: {},
};
