import {
  required,
  maxLength,
  minLength,
  number,
  email,
  regex,
  minValue,
  maxValue,
} from "react-admin";

const createValidationFunctionArray = function createValidationFunctionArray(
  validationObject
) {
  const validationFunctionArray = [];
  const validationFunctions = {
    minLength,
    maxLength,
    required,
    number,
    email,
    regex,
    minValue,
    maxValue,
  };
  const MESSAGE_ONLY = ["number", "required", "email"];
  if (!validationObject) return validationFunctionArray;
  Object.keys(validationFunctions).forEach((key) => {
    if (validationObject[key]) {
      const validateOn = validationObject[key];
      // eslint-disable-next-line max-len
      if (MESSAGE_ONLY.includes(key)) {
        validationFunctionArray.push(
          validationFunctions[key](validationObject.validationMessage)
        );
      } else {
        // eslint-disable-next-line max-len
        validationFunctionArray.push(
          validationFunctions[key](
            validateOn,
            validationObject.validationMessage
          )
        );
      }
    }
  });
  return validationFunctionArray;
};

export default createValidationFunctionArray;
