import * as Choices from "../../SelectChoices";

export default {
  sub_resource_config: {
    tab_name: "Address",
    backend_resource: "member_dependent_addresses",
    linking_field: "member_dependent_id",
    timeline_identifiers: [
      {
        label: "Address Type",
        name: "address_type",
        choices: Choices.MEMBER_ADDRESS_TYPE_CHOICES,
      },
    ],
    attributes: [
      {
        source: "street_one",
        label: "Street Line 1",
        validations: { required: true },
      },
      {
        source: "street_two",
        label: "Street Line 2",
      },
      {
        source: "city",
        label: "City",
        validations: { required: true },
      },
      {
        source: "state",
        label: "State",
        type: "drop-down",
        choices: Choices.US_STATES,
        validations: { required: true, maxLength: 2, minLength: 2 },
      },
      {
        source: "zip_code",
        label: "Zip Code",
        validations: { required: true },
      },
      {
        source: "country",
        label: "Country",
        type: "drop-down",
        choices: Choices.COUNTRIES,
        defaultValue: "USA",
        validations: { required: true },
      },
    ],
    components: ["History"],
  },
  top_level_resource_config: {
    title: "Member Dependent Addresses",
    backend_resource: "member_dependent_addresses",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "address_type", label: "Type" },
      { source: "street_one", label: "Street Line 1" },
      { source: "street_two", label: "Street Line 2" },
      { source: "city", label: "City" },
      { source: "state", label: "State" },
      { source: "zip_code", label: "Zip Code" },
      { source: "country", label: "Country" },
      { source: "start_date", type: "date", label: "Start Date" },
    ],
    components: ["Show"],
  },
};
