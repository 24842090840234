import * as React from "react";
import PropTypes from "prop-types";
import currency from "currency.js";
import {
  Labeled,
  SelectField,
  FunctionField,
  useRecordContext,
} from "react-admin";

const CONTRIBUTION_BASES = [
  { name: "Flat Amount", id: "flat_amount" },
  { name: "Percent", id: "percent" },
];

const ContributionFieldComponent = function ContributionFieldComponent({
  source,
  labelPrefix: label,
  sortable,
}) {
  const record = useRecordContext();
  const renderCurrency = ({ [`${source}_amount`]: value }) =>
    `$${currency((value || 0) / 100)}`;
  const renderPercent = ({ [`${source}_amount`]: value }) => `${value}%`;
  const renderFunction =
    record[`${source}_basis`] === "flat_amount"
      ? renderCurrency
      : renderPercent;

  return (
    <span key={source}>
      <Labeled>
        <SelectField
          label={`${label} Basis`}
          source={`${source}_basis`}
          choices={CONTRIBUTION_BASES}
          sortable={sortable}
        />
      </Labeled>
      <br />
      <Labeled>
        <FunctionField
          label={`${label} Amount`}
          source={`${source}_amount`}
          render={renderFunction}
          sortable={sortable}
        />
      </Labeled>
    </span>
  );
};

ContributionFieldComponent.propTypes = {
  source: PropTypes.string.isRequired,
  labelPrefix: PropTypes.string.isRequired,
  sortable: PropTypes.bool,
};

ContributionFieldComponent.defaultProps = {
  sortable: true,
};

export default ContributionFieldComponent;
