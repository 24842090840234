import * as React from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../ProcessManager";

const MemberSetupManagerComponent = function MemberSetupManagerComponent(
  props
) {
  const memberId = useRecordContext(props).id;

  return (
    <ProcessManager
      processName="member_setup"
      ownerId={memberId}
      kickoffButtonLabel="Sync member"
    />
  );
};

const MemberSetupManager = function MemberSetupManager() {
  return (
    <MemberSetupManagerComponent
      label="Member Noyo Sync"
      key="MemberSetupManagerComponent"
    />
  );
};

export default MemberSetupManager;
