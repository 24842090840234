import { React } from "react";
import {
  useNotify,
  Datagrid,
  DateField,
  ResourceContextProvider,
} from "react-admin";

import { Attribute } from "../../../../../GenericAttributes";
import { useGetHistory } from "../../../../../../../lib/Hooks";

const timelineLabelString = function timelineLabelString(
  timelineIdentifiers,
  labelKeys
) {
  const identifierKeys = Object.keys(timelineIdentifiers).filter((key) =>
    labelKeys.includes(key)
  );
  const labelValues = identifierKeys.map((key) => timelineIdentifiers[key]);

  return labelValues.join(", ");
};

const HistoryList = function HistoryList(config) {
  const {
    tab_name: resourceName,
    backend_resource: resource,
    attributes,
  } = config;

  const HistoryListComponent = function HistoryListComponent({
    timelineIdentifiers,
    labelKeys,
  }) {
    const notify = useNotify();
    const emptyTimelineLabel = `No ${timelineLabelString(
      timelineIdentifiers,
      labelKeys
    )} ${resourceName} recorded.`;

    const { data = [] } = useGetHistory(
      resource,
      {
        filter: timelineIdentifiers,
      },
      {
        onError: (error) => {
          notify(error.message, { type: "error" });
        },
      }
    );

    const listData = data.map(
      ({ id: internalId, start_date: startDate, ...rest }) => ({
        start_date: startDate,
        internal_id: internalId,
        ...rest,
        id: startDate,
      })
    );

    if (listData.length === 0) {
      listData.push({ [attributes[0].source]: emptyTimelineLabel });
    }

    return (
      <ResourceContextProvider value={resourceName}>
        <Datagrid data={listData} sort={{ field: "start_date", order: "ASC" }}>
          {attributes.map((attribute) => Attribute("List", attribute))}
          <DateField source="start_date" label="Start Date" />
          <DateField source="end_date" label="End Date" />
        </Datagrid>
      </ResourceContextProvider>
    );
  };

  return HistoryListComponent;
};

export default HistoryList;
