import LifeAndDisabilityCompanyOfferingsTab from "./LifeAndDisabilityCompanyOfferingsTab";

export default {
  sub_resource_config: {
    tab_name: "Life and Disability Offerings",
    view_component: LifeAndDisabilityCompanyOfferingsTab(),
    components: ["CustomView"],
  },
  top_level_resource_config: {},
};
