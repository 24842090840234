import React from "react";
import ReactDOM from "react-dom";
import { datadogRum } from "@datadog/browser-rum";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_ENV === "production") {
  datadogRum.init({
    applicationId: `${process.env.REACT_APP_INTERNAL_TOOLS_DD_APPLICATION_ID}`,
    clientToken: `${process.env.REACT_APP_INTERNAL_TOOLS_DD_CLIENT_TOKEN}`,
    site: "datadoghq.com",
    service: "benefits-sage-internal-tools",
    env: process.env.REACT_APP_ENV === "production" ? "prod" : "dev",
    version: `${process.env.REACT_APP_CODE_VERSION}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    defaultPrivacyLevel: "mask",
    enablePrivacyForActionName: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [
      /https:\/\/.*\.justworks\.com/,
      /https:\/\/.*\.crimsonsrage\.com/,
    ],
    traceSampleRate: 100,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
