import React from "react";

import { ReferenceField, FunctionField } from "react-admin";

const fullName = (record) => `${record.last_name}, ${record.first_name}`;

const FullNameLink = function FullNameLink({ source, reference, label }) {
  return (
    <ReferenceField source={source} reference={reference} link="show">
      <FunctionField label={label} render={fullName} />
    </ReferenceField>
  );
};

export default FullNameLink;
