import { React } from "react";
import { useRecordContext } from "react-admin";
import PlanMetadataEditAttributes from "./components/PlanMetadataEditAttributes";
import {
  dentalAttributes,
  medicalAttributes,
  visionAttributes,
} from "./planMetadataAttributes";

const PlanMetadataEditComponent = function PlanMetadataEditComponent(props) {
  const benefit_type = useRecordContext(props).benefit_type;

  if (benefit_type === "medical") {
    return (
      <PlanMetadataEditAttributes
        key="MedicalPlanMetadataAttributes"
        attributes={medicalAttributes}
      />
    );
  } else if (benefit_type === "dental") {
    return (
      <PlanMetadataEditAttributes
        key="DentalPlanMetadataAttributes"
        attributes={dentalAttributes}
      />
    );
  } else {
    return (
      <PlanMetadataEditAttributes
        key="VisionPlanMetadataAttributes"
        attributes={visionAttributes}
      />
    );
  }
};

const PlanMetadataEdit = function PlanMetadataEdit() {
  return <PlanMetadataEditComponent key="PlanMetadataEditComponent" />;
};

export default PlanMetadataEdit;
