import { React } from "react";
import {
  TextField,
  ReferenceField,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
} from "react-admin";
import { Typography, Chip } from "@mui/material";
import get from "lodash/get";

import { useRecordContext } from "react-admin";

const StringArrayField = ({ source }) => {
  const record = useRecordContext();
  const value = get(record, source);

  if (!value) return null;

  return (
    <>
      {value.map((item) => (
        <Chip key={item} label={item} />
      ))}
    </>
  );
};

const NestedReferenceArrayField = ({
  source,
  itemHeading,
  reference,
  referenceLabelSource,
}) => {
  const record = useRecordContext();
  const value = get(record, source);

  if (!value) return null;

  return (
    <ul>
      {value.map((ids) => (
        <li>
          <Typography variant="body2" color="textSecondary" component="p">
            {itemHeading}
          </Typography>
          <ReferenceArrayField
            source="ids"
            record={{ ids }}
            reference={reference}
          >
            <SingleFieldList>
              <ChipField source={referenceLabelSource} />
            </SingleFieldList>
          </ReferenceArrayField>
        </li>
      ))}
    </ul>
  );
};

const NameField = <TextField key="rule_name" source="rule_name" />;

const StatesField = (
  <StringArrayField
    key="states"
    label="States"
    source="applies_to.hq_states"
    sortable={false}
  />
);

const CategoryField = (
  <ReferenceField
    key="category"
    label="Category"
    source="company_carrier_install_document_category_id"
    reference="company_carrier_install_document_categories"
  >
    <TextField source="label" />
  </ReferenceField>
);

const ProvidersField = (
  <ReferenceArrayField
    key="providers"
    label="Providers"
    reference="providers"
    source="applies_to.provider_ids"
    sortable={false}
  >
    <SingleFieldList>
      <ChipField source="name" />
    </SingleFieldList>
  </ReferenceArrayField>
);

const EntityTypesField = (
  <StringArrayField
    key="entity_types"
    label="Entity Types"
    source="applies_to.entity_types"
    sortable={false}
  />
);

const TimeInBusinessField = (
  <StringArrayField
    key="time_in_business"
    label="Time in Business"
    source="applies_to.time_in_business"
    sortable={false}
  />
);

const RequirementsField = (
  <NestedReferenceArrayField
    key="requirements"
    label="Requirements"
    source="requirements"
    itemHeading="Any of:"
    reference="company_carrier_install_document_types"
    referenceLabelSource="label"
  />
);

const Fields = [
  NameField,
  CategoryField,
  StatesField,
  ProvidersField,
  EntityTypesField,
  TimeInBusinessField,
  RequirementsField,
];

export default Fields;
