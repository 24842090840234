/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import {
  Create,
  SimpleForm,
  useCreate,
  useNotify,
  useRefresh,
  useRecordContext,
  Button,
  Toolbar,
  SaveButton,
} from "react-admin";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { Attribute } from "../../../../GenericAttributes";
import { useToggle } from "../../../../../../lib/Hooks";

const SubResourceCreate = function SubResourceCreate(config) {
  const {
    backend_resource: backendResource,
    linking_field: linkingField,
    tab_name: resourceName,
    parent_fields: parentFields,
  } = config;

  const SubResourceCreateComponent = function SubResourceCreateComponent(
    props
  ) {
    const [create, { loading, error }] = useCreate(backendResource);
    const refresh = useRefresh();
    const notify = useNotify();
    const [expanded, toggleExpanded, setExpanded] = useToggle();
    const expandButtonLabel = expanded ? "Collapse" : "New";
    const expandButtonIcon = expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    const enabledToolbar = (
      <Toolbar>
        <SaveButton alwaysEnable={true} />
      </Toolbar>
    );
    const toolbar = expanded ? enabledToolbar : null;
    const record = useRecordContext();
    const linkingId = record.id;
    const parentValues =
      parentFields &&
      Object.fromEntries(
        parentFields.map((fieldName) => [fieldName, record[fieldName]])
      );

    const handleSubmit = async (values) => {
      const modifiedValues = {
        ...values,
        ...parentValues,
        [linkingField]: linkingId,
      };

      create(
        backendResource,
        { data: modifiedValues },
        {
          onSuccess: () => {
            refresh();
            setExpanded(false);
            notify(
              `Successfully created a new record within ${resourceName}`,
              "success"
            );
          },
        }
      );
    };

    if (error) {
      notify(error.body.message, { type: "error" });
    }

    return (
      <div>
        <Create {...props} redirect="false" title=" ">
          <SimpleForm
            onSubmit={handleSubmit}
            disabled={loading || !expanded}
            toolbar={toolbar}
            defaultValues={{ [linkingField]: linkingId }}
          >
            <Button label={expandButtonLabel} onClick={toggleExpanded}>
              {expandButtonIcon}
            </Button>
            {expanded &&
              config.attributes.map((attribute) =>
                Attribute("Create", attribute)
              )}
          </SimpleForm>
        </Create>
      </div>
    );
  };

  return SubResourceCreateComponent;
};

export default SubResourceCreate;
