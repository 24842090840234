import { React } from "react";
import { useRecordContext } from "react-admin";
import { BASE_URL } from "../../../lib/constants";
import DownloadButton from "./DownloadButton";

const DownloadPriceGridsButtonComponent = function DownloadPriceGridsButton(
  props
) {
  const companyBenefitOfferingId = useRecordContext(props).id;
  const url = `${BASE_URL}/company_benefit_offerings/${companyBenefitOfferingId}/export`;
  const fileName = `${companyBenefitOfferingId}_price_grids`;

  return (
    <div>
      <DownloadButton
        label="Download Price Grids"
        url={url}
        fileName={fileName}
      />
    </div>
  );
};

const DownloadPriceGridsButton = function DownloadPriceGridsButton() {
  return (
    <DownloadPriceGridsButtonComponent key="DownloadPriceGridsButtonComponent" />
  );
};

export default DownloadPriceGridsButton;
