const BCBS_HEADERS = [
  "Company Name",
  "Sage Company UUID",
  "Relationship Code",
  "Waive All Coverage",
  "Wave Reason Code",
  "First Name",
  "Mid Init",
  "Last Name",
  "Name Suffix",
  "Date Of Birth",
  "Gender",
  "SSN",
  "Address 1",
  "Address 2",
  "City",
  "State",
  "Zip Code",
  "Home/Cell Phone",
  "Business Phone",
  "Email Address",
  "Marital Status",
  "Employment Status",
  "Job Title",
  "Hire Date",
  "Hrs/Week",
  "COBRA Reason Code",
  "COBRA Start Date",
  "COBRA Projected End Date",
  "IL Cont Reason Code",
  "IL Cont Start Date",
  "IL Cont Projected End Date",
  "Health Coverage (Y/N)",
  "Health Coverage Type",
  "Selected Plan",
  "Health Waive Reason Code",
  "Health Waive Description",
  "Medical Group/IPA #",
  "Medical Group/IPA Name",
  "PCP #",
  "PCP Name",
  "PCP NPI #",
  "WPHCP #",
  "WPHCP Name",
  "WPHCP NPI #",
  "Dental Coverage (Y/N)",
  "Dental Coverage Type",
  "Selected Dental Plan",
  "Dental Waive Reason Code",
  "Dental Waive Description",
  "Life Coverage (Y/N)",
  "Job Class Type (2-9 -  Max 3 Life Classes & 10+  Max 6 Life Classes",
  "Term Life",
  "Dependent Life",
  "Short Term Disability (STD)",
  "Salary",
  "Salary Period",
  "Medicare Eligible",
  "Medicare HIC Number",
  "Medicare Reason",
  "Medicare Primary or Secondary",
  "Plan A Start Date",
  "Plan A End Date",
  "Plan B Start Date",
  "Plan B End Date",
  "Signature Date",
  "Billing Category",
  "Product Category",
  "FSA Plan",
  "FSA Employee Election Amount",
  "FSA Employer Contribution Amount",
  "HRA Plan",
  " ",
  "Native Language",
  "Spoken",
  "Written",
  "Ethnicity",
  "Race 1",
  "Race 2",
  "Race 3",
  "Race 4",
  "Race 5",
  "Ethnicity Code",
  "Race Code",
  "Eligibility",
];

export { BCBS_HEADERS };
