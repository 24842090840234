/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../../../../../ProcessManager";

const QleSubmitComponent = function QleSubmitComponent(props) {
  const QualifyingLifeEventId = useRecordContext(props).id;

  return (
    <ProcessManager
      processName="qualifying_life_event"
      ownerId={QualifyingLifeEventId}
      kickoffButtonLabel="Sync QLE with carrier"
    />
  );
};

const QleSubmitManager = function QleSubmitManager() {
  return <QleSubmitComponent label="QLE Noyo Sync" key="QleSubmitComponent" />;
};

export default QleSubmitManager;
