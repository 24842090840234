/*
Description:
A function that returns all QLE relevant enrollment information for any given member. This
includes the following bits of data:
- current plan ID
- current enrollment dependents IDs
- all dependents IDs

Example Return Value:
{
  medical: {
      enrollmentId: 'Enrollment 1 ID',

      enrolledDependentIds: ['dependent1 ID'],
    }
}
*/

/* eslint-disable no-restricted-syntax */
const formatCurrentEnrollmentData = function formatCurrentEnrollmentData(
  memberEnrollments
) {
  const structuredData = {};

  for (const enrollment of memberEnrollments) {
    const {
      id,
      company_plan_offering_id: planOfferingId,
      benefit_type: benefitType,
      enrollment_info: enrollmentInfo,
    } = enrollment;

    const { dependent_ids: enrolledDependentIds } = enrollmentInfo;

    structuredData[benefitType] = {
      enrollmentId: id,
      planOfferingId,
      enrolledDependentIds,
    };
  }

  return structuredData;
};

export default formatCurrentEnrollmentData;
