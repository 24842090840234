import { useState } from "react";

const useInput = (initialValue) => {
  const [inputValue, updateInputValue] = useState(initialValue);
  const changeEventHandler = (event) => updateInputValue(event.target.value);

  return [inputValue, changeEventHandler];
};

export default useInput;
