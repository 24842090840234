/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Tab } from "react-admin";

import {
  SubResourceList,
  SubResourceCreate,
  SubResourceHistory,
} from "./components";

const SubResourceTab = function SubResourceTab(config, props) {
  const tabName = config.tab_name;
  const displayCustom = config.components.includes("CustomView");
  const displayHistory =
    config.components.includes("History") && !displayCustom;
  const displayCreate = config.components.includes("Create") && !displayHistory;
  const displayList = config.components.includes("List") && !displayHistory;

  const SubResourceListComponent = SubResourceList(config);
  const SubResourceCreateComponent = SubResourceCreate(config);
  const SubResourceHistoryComponent = SubResourceHistory(config);

  return (
    <Tab label={tabName} key={tabName}>
      {displayHistory && (
        <SubResourceHistoryComponent
          {...props}
          style={{ paddingBottom: "20px" }}
        />
      )}
      {displayCreate && (
        <SubResourceCreateComponent
          {...props}
          style={{ paddingBottom: "20px" }}
        />
      )}
      {displayList && (
        <SubResourceListComponent
          {...props}
          style={{ paddingBottom: "20px" }}
        />
      )}
      {displayCustom && config.view_component}
    </Tab>
  );
};

export default SubResourceTab;
