import { React } from "react";
import { TextInput, ArrayInput, SimpleFormIterator } from "react-admin";

const ZywavePlansInputComponent = function ZywavePlansInputComponent() {
  const inputStyle = { marginBottom: "5px" };

  return (
    <ArrayInput key="external_plan_names" source="external_plan_names">
      <SimpleFormIterator disableReordering>
        <TextInput sx={inputStyle} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const ZywavePlansInput = function ZywavePlansInput() {
  return <ZywavePlansInputComponent key="ZywavePlansInputComponent" />;
};

export default ZywavePlansInput;
