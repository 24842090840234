import { React } from "react";

import {
  Create,
  TextInput,
  useRefresh,
  SimpleForm,
  useCreateSuggestionContext,
  regex,
  required,
} from "react-admin";

import { Dialog, DialogContent } from "@mui/material";

const CreateDocumentType = function CreateDocumentType() {
  const { onCancel, onCreate } = useCreateSuggestionContext();
  const refresh = useRefresh();

  const mutationOptions = {
    onSuccess: (data) => {
      onCreate(data);
      refresh();
    },
  };
  const validateTypeCode = [
    regex(/^[a-z0-9_]+$/, "Can only contain a-z, 0-9, and _"),
    required(),
  ];

  return (
    <Dialog open onClose={onCancel}>
      <DialogContent>
        <Create
          resource="company_carrier_install_document_types"
          redirect={false}
          mutationOptions={mutationOptions}
        >
          <SimpleForm>
            <TextInput
              label="Document Type Code"
              source="type_code"
              validate={validateTypeCode}
              helperText="Can only contain a-z, 0-9, and _"
              autoFocus
              sx={{ marginBottom: "15px" }}
            />

            <TextInput
              label="Type Label"
              source="label"
              validate={required()}
              autoFocus
            />
          </SimpleForm>
        </Create>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDocumentType;
