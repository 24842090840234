import { React, useState } from "react";
import { useRecordContext, Loading } from "react-admin";
import { Box, Modal, Button, Typography } from "@mui/material";

import { useTakeAction } from "lib/Hooks";
import get from "lodash/get";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ExternalIdFieldComponent = function ({ source, external_id_owner }) {
  const record = useRecordContext();
  const { id: internalId, internal_id: bitemporalInteralId } = record;
  const externalId = get(record, source);
  const [mappedData, setMappedData] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (!externalId) return;
    validate(externalId);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const [takeAction, { isLoading: validationLoading }] = useTakeAction(
    "external_id_mappings",
    { action: "validate" },
    {
      onSuccess: (response) => setMappedData(response.data),
      onError: (response) => setMappedData(response.body.error),
    }
  );

  const validate = (id) => {
    takeAction({
      id,
      options: {
        body: JSON.stringify({
          internal_model_name: "Member::Benefit::EnrollmentState::Model",
          external_id_owner,
          external_id: id,
          internal_id: bitemporalInteralId || internalId,
        }),
      },
    });
  };

  return externalId ? (
    <div>
      <Button onClick={handleOpen}>Show Data</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {validationLoading ? (
            <Loading />
          ) : (
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Benefits Registry Data
              </Typography>
              <pre>{JSON.stringify(mappedData, null, 2)}</pre>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  ) : (
    ""
  );
};

export default ExternalIdFieldComponent;
