import * as Choices from "../../SelectChoices";

const medicalAttributes = [
  {
    source: "metadata.plan_network_type",
    label: "Plan Network Type",
    choices: Choices.PLAN_NETWORK_TYPES,
    type: "drop-down",
    validations: { required: true },
  },
  {
    source: "metadata.disclaimer",
    label: "Disclaimer",
    validations: { required: false },
  },
  {
    source: "metadata.coinsurance",
    label: "Coinsurance",
    validations: { required: true },
  },
  {
    source: "metadata.copay.pcp",
    label: "PCP Copay",
    type: "number",
    validations: { required: true },
  },
  {
    source: "metadata.copay.spc",
    label: "SPC Copay",
    type: "number",
    validations: { required: true },
  },
  {
    source: "metadata.deductible.individual",
    label: "Individual Deductible",
    type: "number",
    validations: { required: true },
  },
  {
    source: "metadata.deductible.family",
    label: "Family Deductible",
    type: "number",
    validations: { required: true },
  },
  {
    source: "metadata.out_of_pocket_max.individual",
    label: "Individual Out of Pocket Max",
    type: "number",
    validations: { required: true },
  },
  {
    source: "metadata.out_of_pocket_max.family",
    label: "Family Out of Pocket Max",
    type: "number",
    validations: { required: true },
  },
  {
    source: "metadata.hsa_compatible",
    label: "HSA Compatible",
    type: "boolean",
    validations: { required: true },
  },
  {
    source: "metadata.mental_health_visit",
    label: "Mental Health Visit",
    type: "boolean",
    validations: { required: true },
  },
  {
    source: "metadata.out_of_network_coverage",
    label: "Out of Network Coverage",
    type: "boolean",
    validations: { required: true },
  },
  {
    source: "metadata.requires_referrals",
    label: "Requires Referrals",
    type: "boolean",
    validations: { required: true },
  },
];

const dentalAttributes = [
  {
    source: "metadata.deductible.individual",
    label: "Individual Deductible",
    type: "number",
    validations: { required: true },
  },
  {
    source: "metadata.deductible.family",
    label: "Family Deductible",
    type: "number",
    validations: { required: true },
  },
  {
    source: "metadata.annual_benefit_max",
    label: "Annual Benefit Max",
    type: "number",
    validations: { required: true },
  },
  {
    source: "metadata.orthodontics",
    validations: { required: true },
    label: "Orthodontics",
    sortable: true,
  },
  {
    source: "metadata.preventative_service",
    validations: { required: true },
    label: "Preventative Service",
    sortable: true,
  },
  {
    source: "metadata.basic_services",
    validations: { required: true },
    label: "Basic Services",
    sortable: true,
  },
  {
    source: "metadata.major_services",
    validations: { required: true },
    label: "Major Services",
    sortable: true,
  },
  {
    source: "metadata.out_of_network_coverage",
    label: "Out of Network Coverage",
    type: "boolean",
    validations: { required: true },
  },
];

const visionAttributes = [
  {
    source: "metadata.vision_deductible",
    validations: { required: true },
    label: "Deductible",
    sortable: true,
  },
  {
    source: "metadata.eye_exam",
    validations: { required: true },
    type: "number",
    label: "Eye Exam",
    sortable: true,
  },
  {
    source: "metadata.eyeglass_lenses",
    validations: { required: true },
    type: "number",
    label: "Eyeglass Lenses",
    sortable: true,
  },
  {
    source: "metadata.frames",
    validations: { required: true },
    label: "Frames",
    sortable: true,
  },
  {
    source: "metadata.contacts",
    validations: { required: true },
    label: "Contacts",
    sortable: true,
  },
];

export { medicalAttributes, dentalAttributes, visionAttributes };
