import * as React from "react";

import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";
import DownloadButton from "./DownloadButton";

import { BASE_URL } from "../../../lib/constants";

const FileFeedDownloadButton = function FileFeedDownloadButton({
  fileVersion,
}) {
  let record = useRecordContext();

  const {
    id,
    internal_encrypted_absolute_path,
    internal_absolute_path,
    status,
  } = record;
  let fileName;

  if (fileVersion === "encrypted") {
    fileName = internal_encrypted_absolute_path.split("/").pop();
  } else {
    fileName = internal_absolute_path.split("/").pop();
  }

  let disabled = status !== "complete";

  return (
    <DownloadButton
      key={fileVersion}
      disabled={disabled}
      label={`Download${fileVersion === "encrypted" ? " Encrypted" : ""}`}
      url={`${BASE_URL}/file_feed_runs/${id}/download/${fileVersion}`}
      fileName={fileName}
    />
  );
};

FileFeedDownloadButton.propTypes = {
  fileVersion: PropTypes.string.isRequired,
};

const FileFeedDownload = (fileVersion) => {
  return <FileFeedDownloadButton key={fileVersion} fileVersion={fileVersion} />;
};

export default FileFeedDownload;
