import React from "react";

import { DateField, FunctionField, ResourceContextProvider } from "react-admin";

import { MatchResolver } from "./components";

import { explainerStyle } from "./styles";

const fullName = (record) => {
  const {
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
  } = record;

  return [firstName, middleName, lastName].join(" ");
};

const ResolveNoyoMemberMismatches = function ResolveNoyoMemberMismatches({
  data,
}) {
  const {
    unmapped_sage_members: unmappedSageMembers,
    unmapped_noyo_employees: unmappedNoyoEmployees,
  } = data;

  const noyoChoices = unmappedNoyoEmployees.map((employee) => ({
    id: employee.id,
    name: fullName(employee),
  }));

  return (
    <div>
      {unmappedSageMembers[0] && (
        <div style={explainerStyle}>
          Match each of the listed members to a Noyo record or if you are sure a
          new record should be created click 'Create In Noyo'
        </div>
      )}
      <ResourceContextProvider value="members">
        {unmappedSageMembers.map((sageObject) => (
          <MatchResolver
            key={sageObject.id}
            sageObject={sageObject}
            noyoChoices={noyoChoices}
          >
            <FunctionField label="Full Name" render={fullName} />
            <DateField source="date_of_birth" />
          </MatchResolver>
        ))}
      </ResourceContextProvider>
    </div>
  );
};

export default ResolveNoyoMemberMismatches;
